import React, { useRef, useState, useEffect, useCallback } from "react";

interface DelayedRenderProps {
  width: string | number;
  height: string | number;
  delay?: number;
  unmountOnHidden?: boolean;
  callback?: { (visible: boolean): unknown };
}
interface DelayedRenderState {
  visible: boolean;
}
export const DelayedRender: React.FC<DelayedRenderProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const visibleTimeout = useRef<NodeJS.Timeout | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const observeHandler = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (visibleTimeout.current) clearTimeout(visibleTimeout.current);
      if (entries[0].isIntersecting && !visible) {
        props.callback && props.callback(true);
        visibleTimeout.current = setTimeout(() => {
          setVisible(true);
          if (!props.unmountOnHidden) {
            if (observer.current && containerRef.current)
              observer.current.unobserve(containerRef.current);
          }
        }, props.delay || 0);
      } else if (
        props.unmountOnHidden &&
        !entries[0].isIntersecting &&
        visible
      ) {
        props.callback && props.callback(false);
        setVisible(false);
      }
    },
    [visible, props.delay, props.unmountOnHidden]
  );

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;

    observer.current = new IntersectionObserver(observeHandler);
    observer.current.observe(elem);

    return () => {
      if (visibleTimeout.current) clearTimeout(visibleTimeout.current);
      if (observer.current && elem) observer.current.unobserve(elem);
      observer.current = null;
    };
  }, [observeHandler]);

  const { width, height, children, ...otherProps } = props;

  return (
    <div
      style={{
        width: !visible ? width : undefined,
        height: !visible ? height : undefined,
        display: "inline-flex",
      }}
      ref={containerRef}
      {...otherProps}
    >
      {visible ? children : null}
    </div>
  );
};
