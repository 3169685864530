import config from "../config";
import authHelper from "../utils/authHelper";

class SecFootageProvider {
  async getVideos(
    camera: string,
    startTime: number,
    endTime: number
  ): Promise<string[]> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(
      `${config(
        "serverPath"
      )}/api/sec_footage/${camera}?start=${startTime}&end=${endTime}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const { videos } = await res.json();
    if (res.status >= 400) throw new Error(res.statusText);
    return videos;
  }

  async listCameras(): Promise<string[]> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/sec_footage/list`, {
      headers: {
        Authorization: token,
      },
    });
    const cameras = await res.json();
    if (res.status >= 400) throw new Error(res.statusText);
    return cameras;
  }
}

const secFootageProvider = new SecFootageProvider();

export default secFootageProvider;
export { SecFootageProvider };
