import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header/header";
import styles from "./secFootage.module.css";
import config from "../../config";
import secFootageProvider from "../../providers/secFootageProvider";
import moment from "moment";
import { getQueryParams, setQueryParams } from "../../utils/queryParams";
import { DelayedRender } from "../../components/delayedRender/DelayedRender";

/**
 
TODO

 - next/prev hour
 - jump to current time
 - save time pos across cameras

 - thumbnail view for one time across multiple days

 */

function getTimeFromVideoName(name: string) {
  const d = name.match(/(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})/);
  if (!d) return 0;

  // 2019-01-01T00:00:00.000Z
  return new Date(
    `${d[1]}-${d[2]}-${d[3]}T${d[4]}:${d[5]}:${d[6]}.000Z`
  ).getTime();
}

const SecurityFootage: React.FC = () => {
  const [cameraList, setCameraList] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoEl = useRef(null);
  const [viewAll, setViewAll] = useState(false);
  const [curDate, setCurDate] = useState(
    getQueryParams().curDate || moment().format("YYYY-MM-DD")
  );
  const [curTime, setCurTime] = useState(
    getQueryParams().curTime || moment().format("HH:00")
  );
  const [curCamera, setCurCamera] = useState(getQueryParams().curCamera || "");
  const dataRef = useRef({ videos, currentVideo, isPlaying, curCamera });

  useEffect(() => {
    dataRef.current = { videos, currentVideo, isPlaying, curCamera };
  });

  useEffect(() => {
    const run = async () => {
      const cams = await secFootageProvider.listCameras();
      setCameraList(cams);
      if (!dataRef.current.curCamera) setCurCamera(cams[0]);
    };
    run();
  }, []);

  useEffect(() => {
    if (!curCamera) return;
    setQueryParams({ ...getQueryParams(), curCamera, curDate, curTime });

    const run = async () => {
      const start = new Date(`${curDate}T${curTime}`).getTime();
      const videos = await secFootageProvider.getVideos(
        curCamera,
        start,
        start + 60 * 60 * 1000
      );
      setVideos(videos);
      setCurrentVideo(videos[0]);
      // setCurrentVideo("");
    };
    run();
  }, [curCamera, curDate, curTime]);

  const priorVideo = () => {
    setCurrentVideo((currentVideo) => {
      const idx = videos.indexOf(currentVideo) - 1;
      if (idx > 0) {
        setIsPlaying(true);
        return videos[idx];
      } else {
        setIsPlaying(false);
        return "";
      }
    });
  };
  const nextVideo = () => {
    const { currentVideo, videos } = dataRef.current;
    const idx = videos.indexOf(currentVideo) + 1;
    if (idx && idx < videos.length) {
      setIsPlaying(true);
      setCurrentVideo(videos[idx]);
    } else {
      setIsPlaying(false);
      setCurrentVideo("");
    }
  };
  const play = () => {
    if (!currentVideo) setCurrentVideo(videos[0]);
    else {
      const el = (videoEl.current as unknown) as HTMLVideoElement;
      el.play();
    }
    setIsPlaying(true);
  };
  const pause = () => {
    if (currentVideo) {
      const el = (videoEl.current as unknown) as HTMLVideoElement;
      el.pause();
    }
    setIsPlaying(false);
  };

  useEffect(() => {
    function registerEvents() {
      if (!videoEl || !videoEl.current) {
        setTimeout(registerEvents, 500);
        return;
      }
      const el = (videoEl.current as unknown) as HTMLVideoElement;
      console.log("event", el);
      const cb = nextVideo;
      el.addEventListener("ended", cb);

      // el.addEventListener("timeupdate", (e) => {
      //   console.log(e.timeStamp);
      // });

      return () => el.removeEventListener("ended", cb);
    }
    return registerEvents();
  }, []);

  const enhancedVideos = videos.map((v) => ({
    url: v,
    time: getTimeFromVideoName(v),
  }));

  let minTime = Math.min(...enhancedVideos.map((v) => v.time));
  let maxTime = Math.max(...enhancedVideos.map((v) => v.time)) + 10000;

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        <div>
          <select
            className={styles.inputField}
            value={curCamera}
            onChange={(e) => setCurCamera(e.target.value)}
          >
            {cameraList.map((c) => (
              <option value={c}>{c}</option>
            ))}
          </select>
          <input
            className={styles.inputField}
            type="date"
            value={curDate}
            onChange={(e) => setCurDate(e.target.value)}
          />
          <input
            className={styles.inputField}
            type="time"
            value={curTime}
            onChange={(e) => setCurTime(e.target.value)}
            step="3600"
          ></input>
        </div>
        <div
          className={styles.footageBar}
          onClick={(e) => {
            const clickPos = e.clientX / window.innerWidth;
            let closestDist = 1;
            let closestVideo = "";
            enhancedVideos.forEach((vid) => {
              const vidPos = (vid.time - minTime) / (maxTime - minTime);
              const dist = Math.abs(clickPos - vidPos);
              if (dist < closestDist) {
                closestDist = dist;
                closestVideo = vid.url;
              }
            });
            setCurrentVideo(closestVideo);
          }}
        >
          {enhancedVideos.map((vid) => {
            return (
              <div
                style={{
                  width: `${(8000 / (maxTime - minTime)) * 100}%`,
                  left: `${
                    ((vid.time - minTime) / (maxTime - minTime)) * 100
                  }%`,
                  background: vid.url === currentVideo ? "#f00" : "#000",
                  height: vid.url === currentVideo ? 40 : undefined,
                  marginTop: vid.url === currentVideo ? -10 : undefined,
                  minWidth: vid.url === currentVideo ? 2 : undefined,
                }}
                className={styles.footage}
              ></div>
            );
          })}
        </div>
        <div>
          <span className={styles.controlButton} onClick={priorVideo}>
            ⏪
          </span>
          {isPlaying ? (
            <span className={styles.controlButton} onClick={pause}>
              ⏸️
            </span>
          ) : (
            <span className={styles.controlButton} onClick={play}>
              ▶️
            </span>
          )}
          <span className={styles.controlButton} onClick={nextVideo}>
            ⏩
          </span>
          <a
            href={`${config("serverPath")}${currentVideo}`}
            download
            className={styles.controlButton}
          >
            ⬇️
          </a>
        </div>
        {currentVideo ? (
          <video
            ref={videoEl}
            style={{ width: "100%" }}
            src={`${config("serverPath")}${currentVideo}`}
            autoPlay
          />
        ) : null}

        <div style={{ margin: 40 }} onClick={() => setViewAll(!viewAll)}>
          View All
        </div>

        {viewAll
          ? videos.map((src) => (
              <DelayedRender width={320} height={180} key={src}>
                <img
                  className={styles.previewVideo}
                  src={`${config("serverPath")}${src.replace(".mp4", ".jpg")}`}
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                    setCurrentVideo(src);
                  }}
                />
              </DelayedRender>
            ))
          : null}
      </div>
    </React.Fragment>
  );
};

export default SecurityFootage;
