import { deepCopy } from "../../../utils/deepCopy";
import { objectKeys } from "../../../utils/objectKeys";
import { Units } from "../models/cookbookModels";


const unitConversions: Units = {
  cup: { // US cup
    tsp: 48,
    Tbsp: 16,
    pinch: 768,
    "fl oz": 8, // fluid
    ml: 236.588,
    "cup metric": 0.946353,
  },
  kg: {
    lb: 2.20462,
    oz: 35.274, // dry
    g: 1000,
  },
  clove: {},
  fillet: {},
  count: {
    piece: 1,
  },
  package: {},
  head: {},
  stick: {},
  bunch: {},
  bundle: {},
  slice: {}
}

const enhanceConversions = (units: Units) => {
  for (let i = 0; i < 3; i++) {
    objectKeys(units).forEach(parentUnit => {
      units[parentUnit][parentUnit] = 1;
      objectKeys(units[parentUnit]).forEach(unitA => {
        objectKeys(units[parentUnit]).forEach(unitB => {
          units[unitA] = units[unitA] || {};
          units[unitA][unitB] = units[parentUnit][unitB] / units[parentUnit][unitA];
        })
      })
    })
  }
  return units;
}
enhanceConversions(unitConversions);

export const ehanceCustomConversions = (units: Units) => {
  units = deepCopy(units);
  const newConversions = deepCopy(unitConversions);
  objectKeys(units).forEach(a => {
    newConversions[a] = newConversions[a] || {};
    objectKeys(units[a]).forEach(b => {
      newConversions[a][b] = units[a][b];
    })
  })
  return enhanceConversions({
    ...newConversions,
    ...units,
  })
}

export const convert = (unitA: string, unitB: string, units = unitConversions, itemDebug = '') => {

  const val = units[unitA] && units[unitA][unitB];
  if (!val) throw new Error('Cannot Convert ' + unitA + ' to ' + unitB + ' for ' + itemDebug);

  return val || 0;
}

export const getAllUnits = () => {
  const units = Object.keys(unitConversions).map(s => s.toLocaleLowerCase());
  units.sort();
  return units;
}
