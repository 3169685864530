import { useEffect, useRef, useState } from "react";

export function useAsyncAsLocal<T>(getterFn: { (): Promise<T> }, setterFn: { (val: T): unknown }, defaultValue: T) {

  const [val, setVal] = useState<T>(defaultValue);
  const timer = useRef(null as any);
  const lastServer = useRef(null as T | null);

  const refresh = () => {
    getterFn().then(res => {
      setVal(res);
      lastServer.current = res;
    });
  }
  useEffect(refresh, []);

  useEffect(() => {
    if (!val) return;
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (val !== lastServer.current) {
        setterFn(val);
        lastServer.current = val;
        timer.current = null;
      };
    }, 5000)
  }, [val]);

  return [val, setVal, refresh] as [typeof val, typeof setVal, typeof refresh];
};
