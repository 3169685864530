export function debounce<T extends { (...arg: any[]): void }>(method: T, delay = 1000): T {
  let timeout: NodeJS.Timeout;
  const debounced = (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => method(...args), delay);
  };
  return debounced as any;
}

const debounceTimeouts: { [key: string]: NodeJS.Timeout } = {};

export function callDebounced<T extends { (): void }>(
  method: T,
  options?: {
    id?: string;
    delay?: number;
  }
): void {
  const DELAY = (options && options.delay) || 200;
  const key = (options && options.id) || method.toString();

  clearTimeout(debounceTimeouts[key]);
  debounceTimeouts[key] = setTimeout(() => {
    delete debounceTimeouts[key];
    method();
  }, DELAY);
}
