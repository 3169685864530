import React from "react";
import { toTitleCase } from "../../../utils/stringFormatting";

const pages = ['search', 'planning', 'ingredients', 'shopping', 'admin'] as const;

interface Props {
  currentPage: typeof pages[number];
}

export const CookbookMenu = ({ currentPage }: Props) => {

  return (
    <div style={{ padding: 20, textAlign: 'center' }}>
      {pages.map((page, idx) => {
        return (
          <>
            {idx !== 0 ? <span style={{ display: 'inline-block', width: 30, textAlign: 'center' }}>|</span> : null}
            <a key={page} href={'/home/cookbook/' + page} style={{ color: '#000', textDecoration: 'none', fontWeight: currentPage === page ? 'bold' : 'normal' }}>{toTitleCase(page)}</a>
          </>
        )
      })}
    </div>
  )
}

