import React, { useEffect, useState } from "react";
import { Ingredient, IngredientAmount, Recipe } from "../models/cookbookModels"
import { getIngredientNutrition, getRecipeNutrition } from "../utils/recipeHelper"
import Input from "../../../components/input/input";
import Option from "../../../components/option/option";
import Button from "../../../components/button/button";
import { IngredientFormShort } from "./IngredientForm";
import { ImageUpload } from "./ImageUpload";

interface Props {
  recipe: Recipe | null;
  onCancel: { (): unknown };
  onSave: { (recipe: Recipe): unknown };
}

const newRecipe = () => ({
  id: '',
  name: '',
  webUrl: '',
  directions: '',
  type: '',
  ingredients: [],
} as Recipe);

const newIngredient = () => ({
  item: {
    name: '',
    unit: 'g',
    type: 'other',
  },
  quantity: 1,
} as IngredientAmount);


export const RecipeForm = ({ recipe, onCancel, onSave }: Props) => {

  const [localRecipe, setLocalRecipe] = useState(recipe || newRecipe());
  useEffect(() => {
    if (recipe) setLocalRecipe(recipe);
  }, [recipe]);

  return (
    <div style={{ display: 'inline-block', border: '1px solid black', margin: 10, padding: 10 }}>
      <table>
        <tbody>
          <tr>
            <td>Name:</td>
            <td><Input value={localRecipe.name} onChange={(name) => setLocalRecipe({ ...localRecipe, name })} style={{ width: 480 }} /></td>
          </tr>
          <tr>
            <td>Web URL:</td>
            <td><Input value={localRecipe.webUrl || ''} onChange={(webUrl) => setLocalRecipe({ ...localRecipe, webUrl })} style={{ width: 480 }} /></td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>
              <Option
                value={{ label: localRecipe.type || '' }}
                options={['', 'Meal', 'Protein', 'Veggie', 'Starch'].map(label => ({ label }))}
                onChange={(value) => setLocalRecipe({ ...localRecipe, type: value.label })}
                style={{ width: 480 }}
              />
            </td>
          </tr>
          <tr>
            <td>Image:</td>
            <td>
              <ImageUpload imageUrl={localRecipe.imageUrl} onUpload={(imageUrl) => setLocalRecipe({ ...localRecipe, imageUrl })} />
            </td>
          </tr>
          <tr>
            <td>Directions:</td>
            <td><Input value={localRecipe.directions || ''} onChange={(directions) => setLocalRecipe({ ...localRecipe, directions })} style={{ width: 480 }} /></td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'top' }}>Ingredients:</td>
            <td>
              {(localRecipe.ingredients || []).map((ingredient, index) => {
                return (
                  <div>
                    <IngredientFormShort ingredient={ingredient} onChange={ingredient => {
                      const newRecipe = { ...localRecipe, ingredients: [...(localRecipe.ingredients || [])] };
                      newRecipe.ingredients.splice(index, 1, ingredient);
                      setLocalRecipe(newRecipe);
                    }} />
                    <button onClick={() => {
                      const newRecipe = { ...localRecipe, ingredients: [...(localRecipe.ingredients || [])] };
                      newRecipe.ingredients.splice(index, 1);
                      setLocalRecipe(newRecipe);
                    }}>-</button>
                  </div>
                );
              })}
              <button onClick={() => {
                setLocalRecipe({ ...localRecipe, ingredients: [...(localRecipe.ingredients || []), newIngredient()] });
              }}>+</button>
            </td>
          </tr>

        </tbody>
      </table>
      <br /><br /><br />
      <div>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave(localRecipe)}>Save</Button>
      </div>
    </div>
  )
}
