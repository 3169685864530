import userProvider from "../providers/userProvider";
import storageHelper from "./storageHelper";
import { useAsync } from "./useAsync";

export const useUser = <T>() => {
  const [user] = useAsync(userProvider.getCached, [], storageHelper.get('user'));
  const isAdmin = user && user.capabilities.includes('admin');
  const hasCapability = (capability: string) => user && user.capabilities.includes(capability);
  return { isAdmin, hasCapability, user };
};
