import config from "../config";
import authHelper from "../utils/authHelper";

interface User {
  capabilities: string[];
  loggedIn: number;
  username: string;
}

let user: User | null = null;

class UserProvider {
  async get(): Promise<User> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/user`, {
      headers: {
        Authorization: token
      }
    });
    const msg = await res.json();
    user = msg;
    return msg;
  }

  async getCached(): Promise<User> {
    if (user) return user;
    return (this || userProvider).get();
  }

  getCachedSync() {
    return user;
  }
}

const userProvider = new UserProvider();

export default userProvider;
export { UserProvider };
