import config from "../config";
import authHelper from "../utils/authHelper";

const imageFallback = () =>
  new Promise((resolve) =>
    setTimeout(() => resolve({ blob: () => "" } as any), 5000)
  ) as Promise<Response>;

class CameraProvider {
  async getTotalCameras() {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/img/count`, {
      headers: {
        Authorization: token,
      },
    });
    const msg = await res.json();
    return msg.count;
  }
  async getCameraImage(cam: number): Promise<Blob> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const get = fetch(`${config("serverPath")}/api/img/${cam}`, {
      method: "GET",
      headers: {
        Authorization: token, // "Bearer " + authHelper.getToken()
      },
    });
    const res = await Promise.race([get, imageFallback()]);
    const blob = await res.blob();
    return blob;
  }
}

const cameraProvider = new CameraProvider();

export default cameraProvider;
export { CameraProvider };
