import React from "react";
import styles from "./option.module.css";

interface Option<T> {
  label: string;
  value?: T;
}

interface PropTypes<T> {
  onChange: { (newVal: Option<T>): unknown };
  value: Option<T>;
  options: Option<T>[];
  style?: React.CSSProperties;
}

function Option<T>(props: PropTypes<T>) {
  return (
    <select
      value={props.value.label}
      className={styles.container}
      style={props.style}
      onChange={el => {
        const selected = props.options.find(v => v.label === el.target.value);
        if (selected) props.onChange({ ...selected });
      }}
    >
      {props.options.map(o => {
        return <option selected={props.value.label === o.label} value={o.label}>{o.label}</option>
      })}
    </select>
  );
};

export default Option;
