import { useEffect, useState } from "react"

export const useAsync = <T>(cb: { (): Promise<T> }, args: any[] = [], defaultValue: T) => {
  const [val, setVal] = useState<T>(defaultValue);
  const refresh = () => cb().then(res => {
    setVal(res);
  });
  useEffect(() => { refresh(); }, args);

  return [val, setVal, refresh] as [typeof val, typeof setVal, typeof refresh];
};
