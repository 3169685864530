import React from "react";
import styles from "./spinner.module.css";

interface PropTypes {}

const Spinner: React.FC<PropTypes> = props => {
  return (
    <div className={styles.container}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
