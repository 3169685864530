interface StorageItem {
  expiration: number;
  value: any;
}

interface DataStore {
  [prop: string]: StorageItem;
}

const USE_LOCAL_STORE = typeof localStorage !== "undefined";
const LOCAL_STORE_KEY = "ARTEM_DATA_STORE";
const VALUE_EXPIRATION = 14 * 24 * 60 * 60 * 1000;
const EXPIRATION_CHECK_INTERVAL = 60 * 60 * 1000;

class StorageHelper {
  memoryStore: DataStore = {};
  lastExpirationCheck = 0;
  _checkForExpired() {
    if (Date.now() < this.lastExpirationCheck + EXPIRATION_CHECK_INTERVAL)
      return;
    this.lastExpirationCheck = Date.now();
    const store = this._getStore();
    Object.keys(store).forEach(key => {
      if (store[key].expiration < Date.now()) delete store[key];
    });
    this._setStore(store);
  }

  _getStore(): DataStore {
    if (USE_LOCAL_STORE) {
      const dataStr = localStorage.getItem(LOCAL_STORE_KEY);
      if (dataStr) return JSON.parse(dataStr);
    }
    return this.memoryStore;
  }

  _setStore(value: DataStore) {
    this._checkForExpired();
    this.memoryStore = value;
    if (USE_LOCAL_STORE) {
      localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify(value));
    }
  }

  set(key: string, value: any, expiration = VALUE_EXPIRATION) {
    const store = this._getStore();
    store[key] = {
      expiration: Date.now() + expiration,
      value
    };
    this._setStore(store);
    return value;
  }

  get(key: string) {
    const data = this._getStore()[key];
    if (!data) return;
    if (data.expiration < Date.now()) return this.clear(key);
    return data.value;
  }

  clear(key: string) {
    const data = this._getStore();
    delete data[key];
    this._setStore(data);
  }
}

const storageHelper = new StorageHelper();
export default storageHelper;
export { StorageHelper };
