import React, { useEffect, useRef, useState } from "react";
import Toggle from "../../components/toggle/toggle";
import { callDebounced } from "../../utils/debounce";
import { useDrag } from "../../utils/useDrag";
import styles from "./controlDoorButton.module.css";
import doorOpen from "./door_open.png";
import doorClose from "./door_close.png";
import motion from "./motion.png";

export interface DoorStatus {
  open: boolean;
  opening: boolean;
  closing: boolean;
  lastOpen: number;
  lastClosed: number;
}

interface ControlDoorButtonProps {
  status?: Partial<DoorStatus>;
  onChange: { (status: Partial<DoorStatus>): unknown };
  name: string;
}
export const ControlDoorButton = (props: ControlDoorButtonProps) => {
  const status = props.status || {};

  const open = () => {
    const res = window.confirm('Open Garage Door?')
    if (res) props.onChange({ closing: false, opening: true });
  };
  const close = () => {
    const res = window.confirm('Close Garage Door?');
    if (res) props.onChange({ closing: true, opening: false });
  }

  return (
    <div className={styles.container}>
      <div
        className={!status.open ? styles.fill : styles.fillOn}
      >
        <div className={styles.content}>
          <span className={styles.icon} onClick={close}>
            ⬇
          </span>
          <span>
            {status.opening ? 'Opening' : status.closing ? 'Closing' : status.open ? 'Open' : 'Closed'}<br /> {props.name}
          </span>
          <span className={styles.icon} onClick={open}>
            ⬆
          </span>
        </div>
      </div>
    </div>
  );
};
