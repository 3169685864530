import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";

import { callDebounced } from "../../utils/debounce";
import { CookbookMenu } from "./components/CookbookMenu";
import { CookpadRecipe, searchProvider } from "./providers/searchProvider";
import Input from "../../components/input/input";
import { DelayedRender } from "../../components/delayedRender/DelayedRender";

export const RecipeSearch: React.FC = () => {

  const [recipeResults, setRecipeResults] = useState<CookpadRecipe[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  useEffect(() => {
    callDebounced(() => searchProvider.search(searchText).then(results => setRecipeResults(results)), { delay: 500 });
  }, [searchText]);

  return (
    <React.Fragment>
      <Header />
      <CookbookMenu currentPage="search" />
      <div>
        <Input value={searchText} onChange={setSearchText} style={{
          width: 'calc(100% - 40px)',
          margin: 20,
          height: 60,
          padding: 20,
        }} />
        {recipeResults.map(recipe => (
          <div style={{ padding: 10, borderBottom: '1px solid #ccc', maxWidth: 600, boxSizing: 'border-box', margin: '0 auto' }}>
            <DelayedRender width='100%' height={300}>
              <div>
                <h2><a href={`https://cookpad.com/recipe/${recipe.id}`} style={{ textDecoration: 'none', color: 'inherit' }} target="_blank">{recipe.title}</a></h2>
                <a href={`https://cookpad.com/recipe/${recipe.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'inline-block' }} target="_blank">
                  <img src={recipe.image} style={{ width: 200, height: 200, objectFit: 'fill', display: 'inline-block' }} />
                </a>
                <div style={{ display: 'inline-block', width: 'calc(100% - 204px)', verticalAlign: 'top', padding: '0 10px', boxSizing: 'border-box' }}>
                  <p style={{ margin: 0 }}><b>{recipe.makeCount}</b> makes</p>
                  <p>{recipe.ingredients.map(ing => `${ing.name} ${ing.amount}`).join(', ').slice(0, 200)}</p>
                </div>
              </div>
            </DelayedRender>
          </div>
        ))}
      </div>
    </React.Fragment >
  );
};
