import React from "react";
import styles from "./iconHamburger.module.css";

interface PropTypes {
  onChange: { (newVal: boolean): unknown };
  value: boolean;
}

const IconHamburger: React.FC<PropTypes> = props => {
  const isOpenClass = [
    styles.container,
    props.value ? styles.selected : ""
  ].join(" ");
  return (
    <div className={isOpenClass} onClick={() => props.onChange(!props.value)}>
      <div className={styles.bar1} />
      <div className={styles.bar2} />
      <div className={styles.bar3} />
    </div>
  );
};

export default IconHamburger;
