import React from "react";
import styles from "./modal.module.css";

interface PropTypes {
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

function Modal({ children }: PropTypes) {

  return (
    <>
      <div className={styles.modalBackground}></div>
      <div className={styles.modalContainer}>
        <div className={styles.modalInner}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
