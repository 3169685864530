import React, { useState } from "react";
import Header from "../../components/header/header";
import styles from "./settings.module.css";
import fastPropertyProvider from "../../providers/fastPropertyProvider";
import {
  toSnakeCase,
  toTitleCase,
  removeUnderscores,
} from "../../utils/stringFormatting";
import Toggle from "../../components/toggle/toggle";
import Input from "../../components/input/input";
import SettingsRow from "../../components/settingsRow/settingsRow";
import healthProvider from "../../providers/healthProvider";

const formatFp = (fp: string) =>
  toTitleCase(removeUnderscores(toSnakeCase(fp)));

const Settings: React.FC = () => {
  const [config, setConfig] = useState({} as { [key: string]: any });

  let updateTimeout = 0;
  const setValue = (key: string) => (value: any) => {
    const newValue = { ...config, [key]: value };
    setConfig(newValue);
    clearTimeout(updateTimeout);
    updateTimeout = window.setTimeout(
      () => fastPropertyProvider.put(newValue),
      1000
    );
  };

  if (!Object.keys(config).length) {
    fastPropertyProvider.get().then(setConfig);
  }

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        {Object.keys(config).map((key) => (
          <SettingsRow
            key={key}
            description={formatFp(key)}
            input={
              typeof config[key] === "boolean" ? (
                <Toggle value={config[key]} onChange={setValue(key)} />
              ) : (
                <Input value={config[key]} onChange={setValue(key)} />
              )
            }
          />
        ))}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* TODO cofirmation and countdown - it takes 90 seconds to reboot */}
        <button onClick={() => healthProvider.reboot()}>Reboot</button>
      </div>
    </React.Fragment>
  );
};

export default Settings;
