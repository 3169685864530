import config from "../config";
import authHelper from "../utils/authHelper";

const imageFallback = () =>
  new Promise((resolve) =>
    setTimeout(() => resolve({ blob: () => "" } as any), 5000)
  ) as Promise<Response>;

class GalleryProvider {
  getImageUrl(name: string): string {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    return `${config("serverPath")}/api/gallery/images?name=${encodeURIComponent(name)}&token=${token}`;
  }
  async getImage(name: string): Promise<Blob> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const get = fetch(`${config("serverPath")}/api/gallery/images?name=${encodeURIComponent(name)}`, {
      method: "GET",
      headers: {
        Authorization: token, // "Bearer " + authHelper.getToken()
      },
    });
    const res = await Promise.race([get, imageFallback()]);
    const blob = await res.blob();
    return blob;
  }
  getThumbUrl(name: string) {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    return `${config("serverPath")}/api/gallery/thumbs?name=${encodeURIComponent(name)}&token=${token}`;
  }
  async getThumb(name: string): Promise<Blob> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const get = fetch(`${config("serverPath")}/api/gallery/thumbs?name=${encodeURIComponent(name)}`, {
      method: "GET",
      headers: {
        Authorization: token, // "Bearer " + authHelper.getToken()
      },
    });
    const res = await Promise.race([get, imageFallback()]);
    const blob = await res.blob();
    return blob;
  }
  async listImages(offset = 0, limit = 10) {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/gallery/images/list?offset=${offset}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: token, // "Bearer " + authHelper.getToken()
      },
    });
    const json: string[] = await res.json();
    return json;
  }
}

const galleryProvider = new GalleryProvider();

export default galleryProvider;
export { GalleryProvider };
