import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header/header";
import styles from "./totoControls.module.css";
import switchProvider, { Device } from "../../providers/switchProvider";
import Button from "../../components/button/button";

import backward from './images/backward.png';
import butt_pointy from './images/butt_pointy.png';
import butt_wide from './images/butt_wide.png';
import clean from './images/clean.png';
import dry from './images/dry.png';
import female_pointy from './images/female_pointy.png';
import female_wide from './images/female_wide.png';
import flush_big from './images/flush_big.png';
import flush_small from './images/flush_small.png';
import forward from './images/forward.png';
import front_back from './images/front_back.png';
import open from './images/open.png';
import person1 from './images/person1.png';
import person2 from './images/person2.png';
import pulseImg from './images/pulse.png';
import stop from './images/stop.png';
import stronger from './images/stronger.png';
import toto from './images/toto.png';
import weaker from './images/weaker.png';

import water_1 from './images/water_1.png';
import water_3 from './images/water_3.png';
import water_7 from './images/water_7.png';

const DEBUG_MODE = false;

let toggleDebounce = 0;
const toggle = (device: Device) => {
  if (toggleDebounce + 500 > Date.now()) return console.log('too fast');
  toggleDebounce = Date.now();
  if (DEBUG_MODE) return console.log(device);
  switchProvider.activateSwitch(device);
}

export const TotoControls: React.FC = () => {

  const [state, setState] = useState({
    mode: '',
    pulse: false,
    frontBack: false,
    strength: 0,
    position: 0,
  });

  const clickHandler = (device: Device) => () => {
    toggle(device);
  }
  const clickHandlerMode = (device: Device) => () => {
    setState(s => ({ ...s, mode: device, pulse: false, frontBack: false, strength: 3, position: 3 }));
    toggle(device);
  }
  const clickHandlerBasicMode = (device: Device) => () => {
    setState(s => ({ ...s, mode: device, pulse: false, frontBack: false, strength: 0, position: 0 }));
    toggle(device);
  }
  const clickHandlerStop = (device: Device) => () => {
    setState(s => ({ ...s, mode: '', pulse: false, frontBack: false, strength: 0, position: 0 }));
    toggle(device);
  }
  const clickHandlerPulse = (device: Device) => () => {
    setState(s => ({ ...s, pulse: !s.pulse }));
    toggle(device);
  }
  const clickHandlerFrontBack = (device: Device) => () => {
    setState(s => ({ ...s, frontBack: !s.frontBack }));
    toggle(device);
  }
  const clickHandlerStrength = (device: Device) => () => {
    setState(s => ({ ...s, strength: parseInt((device.match(/[0-9]/) || ['0'])[0]) }));
    toggle(device);
  }
  const clickHandlerPosition = (device: Device) => () => {
    setState(s => ({ ...s, position: parseInt((device.match(/[0-9]/) || ['0'])[0]) }));
    toggle(device);
  }
  const clickHandlerDeltaStrength = (dir: number) => () => {
    const strength = Math.min(Math.max(state.strength + dir, 1), 5);
    setState(s => ({ ...s, strength }));
    toggle(`toto_strength_${strength}` as Device);
  }
  const clickHandlerDeltaPosition = (dir: number) => () => {
    const position = Math.min(Math.max(state.position + dir, 1), 5);
    setState(s => ({ ...s, position }));
    toggle(`toto_front_${position}` as Device);
  }

  const washMode1 = () => {
    setTimeout(clickHandlerMode('toto_butt_spray_wide'), 0);
    setTimeout(clickHandlerFrontBack('toto_front_and_back'), 1000);
    setTimeout(clickHandlerStrength('toto_strength_3'), 2000);
  }
  const washMode2 = () => {
    setTimeout(clickHandlerMode('toto_butt_spray_pointy'), 0);
    setTimeout(clickHandlerFrontBack('toto_front_and_back'), 1000);
    setTimeout(clickHandlerStrength('toto_strength_3'), 2000);
  }
  const washMode3 = () => {
    setTimeout(clickHandlerMode('toto_female_spray_pointy'), 0);
    setTimeout(clickHandlerFrontBack('toto_front_and_back'), 1000);
    setTimeout(clickHandlerStrength('toto_strength_5'), 2000);
  }

  return (
    <React.Fragment>
      <Header />
      <div className={styles.pageContainer}>
        <div className={styles.row}>
          <Button onClick={clickHandlerStop('toto_stop')} flat>
            <img src={stop} className={styles.button} />
          </Button>
        </div>
        <div className={styles.row}>
          <Button onClick={clickHandler('toto_open_lid')} flat><img src={open} className={styles.button} /></Button>
        </div>
        <div className={styles.row}>
          <Button onClick={washMode1} flat><img src={water_1} className={styles.button} /></Button>
          <Button onClick={washMode2} flat><img src={water_3} className={styles.button} /></Button>
          <Button onClick={washMode3} flat><img src={water_7} className={styles.button} /></Button>
        </div>
        <div className={styles.row}>
          <Button onClick={clickHandlerMode('toto_butt_spray_pointy')} flat><img src={butt_pointy} className={[styles.button, state.mode === 'toto_butt_spray_pointy' ? styles.selected : ''].join(' ')} /></Button>
          <Button onClick={clickHandlerMode('toto_butt_spray_wide')} flat><img src={butt_wide} className={[styles.button, state.mode === 'toto_butt_spray_wide' ? styles.selected : ''].join(' ')} /></Button>
        </div>
        <div className={styles.row}>
          <Button onClick={clickHandlerMode('toto_female_spray_pointy')} flat><img src={female_pointy} className={[styles.button, state.mode === 'toto_female_spray_pointy' ? styles.selected : ''].join(' ')} /></Button>
          <Button onClick={clickHandlerMode('toto_female_spray_wide')} flat><img src={female_wide} className={[styles.button, state.mode === 'toto_female_spray_wide' ? styles.selected : ''].join(' ')} /></Button>
        </div>
        <div className={styles.row}>
          <Button onClick={clickHandlerBasicMode('toto_blow')} flat><img src={dry} className={[styles.button, state.mode === 'toto_blow' ? styles.selected : ''].join(' ')} /></Button>
        </div>
        <div className={styles.row}>
          <Button onClick={clickHandlerBasicMode('toto_clean')} flat><img src={clean} className={[styles.button, state.mode === 'toto_clean' ? styles.selected : ''].join(' ')} /></Button>
          <Button onClick={clickHandlerPulse('toto_pulsate_pressure')} flat><img src={pulseImg} className={[styles.button, state.pulse ? styles.selected : ''].join(' ')} /></Button>
          <Button onClick={clickHandlerFrontBack('toto_front_and_back')} flat><img src={front_back} className={[styles.button, state.frontBack ? styles.selected : ''].join(' ')} /></Button>
        </div>

        <div className={styles.row}>
          <Button onClick={clickHandlerDeltaStrength(-1)} flat><img src={weaker} /></Button>
          <Button onClick={clickHandlerStrength('toto_strength_1')} flat><span className={[styles.strength, styles.button, state.strength === 1 ? styles.selected : ''].join(' ')}>1</span></Button>
          <Button onClick={clickHandlerStrength('toto_strength_2')} flat><span className={[styles.strength, styles.button, state.strength === 2 ? styles.selected : ''].join(' ')}>2</span></Button>
          <Button onClick={clickHandlerStrength('toto_strength_3')} flat><span className={[styles.strength, styles.button, state.strength === 3 ? styles.selected : ''].join(' ')}>3</span></Button>
          <Button onClick={clickHandlerStrength('toto_strength_4')} flat><span className={[styles.strength, styles.button, state.strength === 4 ? styles.selected : ''].join(' ')}>4</span></Button>
          <Button onClick={clickHandlerStrength('toto_strength_5')} flat><span className={[styles.strength, styles.button, state.strength === 5 ? styles.selected : ''].join(' ')}>5</span></Button>
          <Button onClick={clickHandlerDeltaStrength(1)} flat><img src={stronger} /></Button>
        </div>

        <div className={styles.row}>
          <Button onClick={clickHandlerDeltaPosition(-1)} flat><img src={backward} /></Button>
          <Button onClick={clickHandlerPosition('toto_front_1')} flat><span className={[styles.strength, styles.button, state.position === 1 ? styles.selected : ''].join(' ')}>1</span></Button>
          <Button onClick={clickHandlerPosition('toto_front_2')} flat><span className={[styles.strength, styles.button, state.position === 2 ? styles.selected : ''].join(' ')}>2</span></Button>
          <Button onClick={clickHandlerPosition('toto_front_3')} flat><span className={[styles.strength, styles.button, state.position === 3 ? styles.selected : ''].join(' ')}>3</span></Button>
          <Button onClick={clickHandlerPosition('toto_front_4')} flat><span className={[styles.strength, styles.button, state.position === 4 ? styles.selected : ''].join(' ')}>4</span></Button>
          <Button onClick={clickHandlerPosition('toto_front_5')} flat><span className={[styles.strength, styles.button, state.position === 5 ? styles.selected : ''].join(' ')}>5</span></Button>
          <Button onClick={clickHandlerDeltaPosition(1)} flat><img src={forward} /></Button>
        </div>

        <div className={styles.row}>
          <Button onClick={clickHandler('toto_flush_hard')} flat><img src={flush_big} className={styles.button} /></Button>
          <Button onClick={clickHandler('toto_flush_soft')} flat><img src={flush_small} className={styles.button} /></Button>
        </div>

        <div className={styles.row}>
          <Button onClick={clickHandler('toto_person_1')} flat><img src={person1} className={styles.button} /></Button>
          <Button onClick={clickHandler('toto_person_2')} flat><img src={person2} className={styles.button} /></Button>
        </div>
        <div className={styles.row}>
          <img src={toto} />
        </div>

      </div>
    </React.Fragment >
  );
};

export default TotoControls;
