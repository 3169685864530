import config from "../../../config";
import authHelper from "../../../utils/authHelper";

import { Ingredient, Units } from '../models/cookbookModels';
import { ehanceCustomConversions } from '../utils/units';
import { deepCopy } from '../../../utils/deepCopy';
import { cacheFn } from "../../../utils/cacheFn";

export const denormalizeIngredient = (ingredient: Ingredient) => {
  ingredient = deepCopy(ingredient);
  if (ingredient.conversions) ingredient.allConversions = ehanceCustomConversions(ingredient.conversions as Units);
  return ingredient;
}

export const normalizeIngredient = (ingredient: Ingredient) => {
  ingredient = deepCopy(ingredient);
  delete ingredient.allConversions;
  return ingredient;
}

export const ingredientProvider = {
  getAll: cacheFn(async () => {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/ingredients`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    });
    const ingredients = await res.json() as Ingredient[];

    return ingredients.map(denormalizeIngredient);
  }, 500),
  add: async (ingredient: Ingredient): Promise<Ingredient> => {
    ingredient = normalizeIngredient(ingredient);
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/ingredients`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(ingredient, null, 4),
    });
    ingredient = await res.json() as Ingredient;

    return ingredient;
  },
  update: async (ingredient: Ingredient): Promise<Ingredient> => {
    ingredient = normalizeIngredient(ingredient);
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/ingredients`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(ingredient, null, 4),
    });
    ingredient = await res.json() as Ingredient;

    return ingredient;
  },
  resetDB: () => { },
}