import config from "../config";
import authHelper from "../utils/authHelper";

class AnnouncementProvider {
  async comingHome(): Promise<string> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(
      `${config("serverPath")}/api/announce/coming_home`,
      {
        method: "POST",
        headers: {
          Authorization: token // "Bearer " + authHelper.getToken()
        }
      }
    );
    const msg = await res.text();
    if (res.status >= 400) throw new Error(msg);
    return msg;
  }
}

const announcementProvider = new AnnouncementProvider();

export default announcementProvider;
export { AnnouncementProvider };
