import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import styles from "./home.module.css";
import cameraProvider from "../../providers/cameraProvider";
import announcementProvider from "../../providers/announcementProvider";
import Spinner from "../../components/spinner/spinner";
import AlarmSettings from "../../components/alarmSettings/alarmSettings";
import { Link } from "react-router-dom";
import infoProvider from "../../providers/infoProvider";
import config from "../../config";
import deviceProvider from "../../providers/deviceProvider";
import fastPropertyProvider from "../../providers/fastPropertyProvider";
import { useUser } from "../../utils/useUser";
import Button from "../../components/button/button";

type RequestStatus = "NONE" | "PENDING" | "SUCCESS" | "FAILURE";


const Home: React.FC = () => {
  const [comingHomeStatus, setComingHomeStatus] = useState(
    "NONE" as RequestStatus
  );

  const [images, setImages] = useState([] as string[]);
  const [notification, setNotification] = useState("");
  const { isAdmin, hasCapability } = useUser();

  useEffect(() => {
    if (2 > 1) return;
    async function getImages() {
      const totalCameras = await cameraProvider.getTotalCameras();
      const fetchImages: Promise<string>[] = [];

      for (let i = 0; i < totalCameras; i++) {
        fetchImages.push(
          (async () => {
            try {
              const imageBlob = await cameraProvider.getCameraImage(i);
              const image = URL.createObjectURL(imageBlob);
              return image;
            } catch (e) {
              return "";
            }
          })()
        );
      }

      const newImages = await Promise.all(fetchImages);
      setImages(newImages);
    }

    async function getCertificate() {
      const info = await infoProvider.getCertInfo();
      const expires = new Date(info.expires);
      if (Date.now() + 14 * 24 * 60 * 60 * 1000 > expires.getTime()) {
        setNotification(
          `Certificate Expires on ${expires.toLocaleDateString()}`
        );
      }
    }
    async function getStorage() {
      const info = await infoProvider.getStorageInfo();
      let maxUsage = 0;
      Object.keys(info).forEach((key) => {
        const usage = info[key];
        if (usage > 75 && usage > maxUsage) {
          maxUsage = usage;
          setNotification(`Mount ${key} usage at ${usage}%`);
        }
      });
    }
    setTimeout(() => {
      getCertificate();
      getStorage();
      getImages();
    }, 500);
  }, []);

  function clickComingHome() {
    setComingHomeStatus("PENDING");
    announcementProvider
      .comingHome()
      .then(() => setComingHomeStatus("SUCCESS"))
      .catch((e) => {
        setComingHomeStatus("FAILURE");
        console.error(e);
      });
  }

  function openGarage() {
    const res = window.confirm('Open Garage Door?');
    if (res) deviceProvider.put('garageDoor', { status: { opening: true, closing: false } });
  }
  function closeGarage() {
    const res = window.confirm('Close Garage Door?');
    if (res) deviceProvider.put('garageDoor', { status: { opening: false, closing: true } });
  }
  function sleepMode() {
    fastPropertyProvider.put({ sleepMode: true });
  }

  return (
    <React.Fragment>
      <Header />
      {notification ? (
        <div className={styles.notificationBanner}>{notification}</div>
      ) : null}
      {hasCapability('coming_home') ? (
        <div className={styles.comingHomeContainer}>
          {comingHomeStatus === "FAILURE" || comingHomeStatus === "NONE" ? (
            <input type="submit" value="Coming Home" onClick={clickComingHome} />
          ) : comingHomeStatus === "PENDING" ? (
            <Spinner />
          ) : null}
          {comingHomeStatus === "SUCCESS" ? (
            <div className={styles.response}>See you soon!</div>
          ) : comingHomeStatus === "FAILURE" ? (
            <div className={styles.responseError}>Please try again.</div>
          ) : null}
        </div>
      ) : null}
      <div className={styles.viewDevicesContainer}>
        <Button onClick={openGarage}>Open Garage</Button>
        <Button onClick={closeGarage}>Close Garage</Button>
      </div>
      <div className={styles.viewDevicesContainer}>
        <Link to={config("appRoot") + "/devices"}>
          <Button>View Devices</Button>
        </Link>
      </div>
      {isAdmin ? (
        <div className={styles.viewDevicesContainer}>
          <Button onClick={sleepMode}>Sleep Mode</Button>
        </div>) : null}
      <div className={styles.imageContainer}>
        {images.map((image, idx) => (
          <Link to={config("appRoot") + "/cams"} key={idx}>
            <img className={styles.image} alt="cam" src={image} />
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Home;
