import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import styles from "./mediaPlayer.module.css";
import pianoProvider from "../../providers/pianoProvider";
import { removeUnderscores, toTitleCase } from "../../utils/stringFormatting";

function formatSong(f: string) {
  return toTitleCase(
    removeUnderscores(f.replace("/piano/", "").replace(".wav", ""))
  );
}

const Doorman: React.FC = () => {
  const [songs, setSongs] = useState([] as string[]);
  const [lastSong, setLastSong] = useState("");

  useEffect(() => {
    pianoProvider
      .getSongs()
      .then(setSongs)
      .catch(console.error.bind(console));
    pianoProvider
      .getLastPlayed()
      .then(setLastSong)
      .catch(console.error.bind(console));
  }, []);

  const clickHandler = (song: string) => () => {
    pianoProvider.playSong(song).catch(console.error.bind(console));
    setLastSong(song);
  };

  console.log(lastSong, songs);

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        {songs.map(song => (
          <div
            key={song}
            className={
              styles.songListItem +
              " " +
              (song === lastSong ? styles.lastPlayed : "")
            }
            onClick={clickHandler(song)}
          >
            {formatSong(song)}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Doorman;
