import React from "react";
import authHelper from "../../utils/authHelper";
import { Redirect } from "react-router";
import config from "../../config";

const Logout: React.FC = () => {
  authHelper.clearAuth();
  return <Redirect to={`${config("appRoot")}/login`} />;
};

export default Logout;
