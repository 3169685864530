import config from "../config";

interface LoginResponse {
  token: string;
  error: string;
}

class AuthProvider {
  async login(username: string, password: string): Promise<LoginResponse> {
    const res = await fetch(`${config("serverPath")}/api/login`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
        redirect: false
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    return json;
  }
}

const authProvider = new AuthProvider();

export default authProvider;
export { AuthProvider };
