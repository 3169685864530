import config from "../config";
import authHelper from "../utils/authHelper";

class InfoProvider {
  async getCertInfo(): Promise<{ expires: Date }> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/info/cert`, {
      headers: {
        Authorization: token
      }
    });
    const data = await res.json();
    return data;
  }
  async getStorageInfo(): Promise<{ [mount: string]: number }> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/info/storage`, {
      headers: {
        Authorization: token
      }
    });
    const data = await res.json();
    return data;
  }
}

const infoProvider = new InfoProvider();

export default infoProvider;
export { InfoProvider };
