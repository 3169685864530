import React, { useCallback, useState } from "react";
import { ConfigEnv, getConfigEnv, setConfigEnv } from "../../config";
import healthProvider from "../../providers/healthProvider";
import { usePolling } from "../../utils/usePolling";
import styles from "./environmentSelection.module.css";

let lastRefresh = 0;
const REFRESH_INTERVAL = 60 * 1000;

const EnvironmentSelection: React.FC = () => {
  const [env, setEnv] = useState(getConfigEnv());

  // polling for new status
  usePolling(
    useCallback(async () => {
      const now = Date.now();
      if (lastRefresh + REFRESH_INTERVAL > now) return;
      lastRefresh = now;

      const envPriority: ConfigEnv[] = ["dev", "local2", "local", "prod"];
      let foundEnv = false;
      for (let i = 0; i < envPriority.length; i++) {
        try {
          const env = envPriority[i];
          await healthProvider.healthcheck(env);
          if (getConfigEnv() !== env) {
            setEnv(env);
            setConfigEnv(env);
          }
          foundEnv = true;
          break;
        } catch (e) {}
      }
      if (!foundEnv) {
        setEnv("offline");
        setConfigEnv("prod");
      }
    }, []),
    5000
  );

  const color =
    env === "prod"
      ? styles.blue
      : env === "local2"
      ? styles.reallyGreen
      : env === "local"
      ? styles.green
      : env === "offline"
      ? styles.red
      : env === "dev"
      ? styles.black
      : "";

  return <div className={[styles.circle, color].join(" ")} />;
};

export default EnvironmentSelection;
