import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header/header";
import styles from "./gallery.module.css";
import galleryProvider from "../../providers/galleryProvider";
import { DelayedRender } from "../../components/delayedRender/DelayedRender";

const PAGE_SIZE = 25;

export const Gallery: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getImages = async () => {
      const images = await galleryProvider.listImages(offset, PAGE_SIZE);
      setImages(old => [...old, ...images]);
    }
    getImages();
  }, [offset]);

  const reachedEnd = useCallback((visible) => {
    if (visible && offset <= images.length) setOffset(offset + PAGE_SIZE);
  }, [offset, images]);

  return (
    <React.Fragment>
      <Header />
      <div className={styles.imageContainer}>
        {(images || []).map(url => (
          <a href={galleryProvider.getImageUrl(url)} target="_blank">
            <img className={styles.image} alt="img" src={galleryProvider.getThumbUrl(url)} />
          </a>
        ))}
        <DelayedRender key={images.length} unmountOnHidden={true} width={1} height={1} callback={reachedEnd}></DelayedRender>
      </div>
    </React.Fragment >
  );
};

export default Gallery;
