import moment from "moment-timezone";

const knownAcronyms = new Set(["CCM"]);
const knownShortWords = new Set(["up", "of", "in", "on"]);
function isAcronym(str: string) {
  if (str.length <= 2 && !knownShortWords.has(str.toLowerCase())) return true;
  if (knownAcronyms.has(str.toUpperCase())) return true;
  return false;
}

function toTitleCase(str: string) {
  if (typeof str !== "string") return str;
  return str
    .split(" ")
    .map(word =>
      !isAcronym(word)
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word
    )
    .join(" ");
}

function removeUnderscores(str: string) {
  if (typeof str !== "string") return str;
  return str.replace(/_/g, " ");
}

function offsetToPacificTime(date: moment.Moment) {
  if (date.tz("America/Los_Angeles").isDST()) return date.utcOffset("-07:00");
  return date.utcOffset("-08:00");
}

function dateTime(date: number, format?: string) {
  if (typeof date !== "number") return date;
  return offsetToPacificTime(moment(date)).format(format);
}

function relativeTime(date: number) {
  if (typeof date !== "number") return date;
  return moment(date).fromNow();
}

function date(date: number) {
  if (typeof date !== "number") return date;
  return offsetToPacificTime(moment(date)).format("MMM D, YYYY");
}

function dateWithDay(date: number) {
  if (typeof date !== "number") return date;
  return offsetToPacificTime(moment(date)).format("ddd, MMM D, YYYY");
}

function dateRange(date1: number, date2: number) {
  if (!date1 && !date2) return null;
  const str1 = offsetToPacificTime(moment(date1)).format("MMM D, YYYY");
  const str2 = offsetToPacificTime(moment(date2)).format("MMM D, YYYY");
  if (!date1) return str2;
  if (!date2) return str1;
  if (date1 === date2) return str1;
  return `${str1} - ${str2}`;
}

function toSnakeCase(str: string) {
  let lastIsUpper = true;
  return str
    .split("")
    .map(char => {
      if (char.toUpperCase() === char) {
        if (lastIsUpper) return char.toLowerCase();
        lastIsUpper = true;
        return "_" + char.toLowerCase();
      } else {
        lastIsUpper = false;
        return char.toLowerCase();
      }
    })
    .join("");
}

export {
  toTitleCase,
  removeUnderscores,
  dateTime,
  relativeTime,
  date,
  dateWithDay,
  dateRange,
  toSnakeCase
};
