import React from "react";
import styles from "./toggle.module.css";

interface PropTypes {
  onChange: { (newVal: boolean): unknown };
  value: boolean;
}

const Toggle: React.FC<PropTypes> = props => {
  return (
    <span
      className={[styles.container, props.value ? styles.selected : ""].join(
        " "
      )}
      onClick={() => props.onChange(!props.value)}
    >
      <div className={styles.knob} />
    </span>
  );
};

export default Toggle;
