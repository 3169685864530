import { useState } from "react";

export const useDragDrop = <T>() => {

  const [drag, setDrag] = useState<T | null>(null);

  const onDrag = (startElement: T) => () => {
    setDrag(startElement);
  }

  const onDrop = () => {
    setDrag(null);
    return drag;
  }

  return [onDrag, onDrop] as [typeof onDrag, typeof onDrop];
};
