import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ViewCameras from "./containers/viewCameras/viewCameras";
// import GateOpener from "./containers/gateOpener/gateOpener";
// import Doorman from "./containers/doorman/doorman";
// import NearbyDevices from "./containers/nearbyDevices/nearbyDevices";
import Login from "./containers/login/login";
import Home from "./containers/home/home";
import Logout from "./containers/login/logout";
import config from "./config";
import EnvironmentSelection from "./components/environmentSelection/environmentSelection";
import Settings from "./containers/settings/settings";
import Devices from "./containers/devices/devices";
// import Charts from "./containers/charts/charts";
import MediaPlayer from "./containers/mediaPlayer/mediaPlayer";
import SecurityFootage from "./containers/secFootage/secFootage";
import Gallery from "./containers/gallery/gallery";
import TotoControls from "./containers/totoControls/totoControls";
import { MealPlanning } from "./containers/cookbook/MealPlanning";
import { IngredientList } from "./containers/cookbook/IngredientList";
import { ShoppingList } from "./containers/cookbook/ShoppingList";
import { CookbookAdmin } from "./containers/cookbook/CookbookAdmin";
import { RecipeSearch } from "./containers/cookbook/RecipeSearch";

const App: React.FC = () => {
  return (
    <Router>
      <EnvironmentSelection />
      <Route
        path="/"
        exact
        component={() => {
          document.location.href = config("appRoot");
          return null;
        }}
      />
      <Route path={config("appRoot")} exact component={Home} />
      <Route path={`${config("appRoot")}/login`} exact component={Login} />
      <Route path={`${config("appRoot")}/gallery`} exact component={Gallery} />
      <Route path={`${config("appRoot")}/logout`} exact component={Logout} />
      <Route path={`${config("appRoot")}/cams`} exact component={ViewCameras} />
      <Route path={`${config("appRoot")}/toto`} exact component={TotoControls} />
      <Route path={`${config("appRoot")}/cookbook`} exact component={RecipeSearch} />
      <Route path={`${config("appRoot")}/cookbook/search`} exact component={RecipeSearch} />
      <Route path={`${config("appRoot")}/cookbook/planning`} exact component={MealPlanning} />
      <Route path={`${config("appRoot")}/cookbook/ingredients`} exact component={IngredientList} />
      <Route path={`${config("appRoot")}/cookbook/shopping`} exact component={ShoppingList} />
      <Route path={`${config("appRoot")}/cookbook/admin`} exact component={CookbookAdmin} />
      {/* <Route path={`${config("appRoot")}/gate`} exact component={GateOpener} /> */}
      {/* <Route path={`${config("appRoot")}/doorman`} exact component={Doorman} /> */}
      <Route path={`${config("appRoot")}/devices`} exact component={Devices} />
      {/* <Route path={`${config("appRoot")}/charts`} exact component={Charts} /> */}
      <Route
        path={`${config("appRoot")}/media_player`}
        exact
        component={MediaPlayer}
      />
      <Route
        path={`${config("appRoot")}/sec_footage`}
        exact
        component={SecurityFootage}
      />
      <Route
        path={`${config("appRoot")}/settings`}
        exact
        component={Settings}
      />
      {/* <Route
        path={`${config("appRoot")}/nearby`}
        exact
        component={NearbyDevices}
      /> */}
    </Router>
  );
};

export default App;
