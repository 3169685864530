import { deepCopy } from '../../../utils/deepCopy';
import storageHelper from '../../../utils/storageHelper';
import { uuidv4 } from '../../../utils/uuid';
import { Ingredient, Recipe } from '../models/cookbookModels';
import { ingredientProvider } from './ingredientsProvider';
import authHelper from '../../../utils/authHelper';
import config from '../../../config';
import { cacheFn } from '../../../utils/cacheFn';

export const denormalizeRecipe = (recipe: Recipe, ingredientsMap: { [id: string]: Ingredient }) => {
  recipe = deepCopy(recipe);
  (recipe.ingredients || []).forEach(ingredientAmount => {
    const { item } = ingredientAmount;
    // mutate
    ingredientAmount.item = {
      ...ingredientsMap[item.id || ''],
      unit: item.unit, // keep unit the same
    }
  });
  return recipe;
}

export const normalizeRecipe = (recipe: Recipe) => {
  recipe = deepCopy(recipe);
  (recipe.ingredients || []).forEach(ingredientAmount => {
    const { item } = ingredientAmount;
    ingredientAmount.item = {
      id: item.id,
      name: item.name, // for easily readability of the data
      unit: item.unit,
    }
  });
  return recipe;
}

export const recipeProvider = {
  getAll: cacheFn(async () => {
    const ingredients = await ingredientProvider.getAll();
    const ingredientsMap: { [id: string]: Ingredient } = {};
    ingredients.forEach(ingredient => { ingredientsMap[ingredient.id || ''] = ingredient; });

    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/recipes`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    });
    const recipes = await res.json() as Recipe[];

    return recipes.map(recipe => denormalizeRecipe(recipe, ingredientsMap));
  }, 500),
  add: async (recipe: Recipe): Promise<Recipe> => {
    recipe = normalizeRecipe(recipe);
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/recipes`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(recipe, null, 4),
    });
    recipe = await res.json() as Recipe;
    return recipe;
  },
  update: async (recipe: Recipe): Promise<Recipe> => {
    return recipeProvider.add(recipe);
  },
  resetDB: () => { },
}