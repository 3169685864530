import React from "react";
import { Redirect } from "react-router";
import authHelper from "../../utils/authHelper";
import config from "../../config";

const LoginRequired: React.FC = () => {
  if (authHelper.getToken() === null)
    return <Redirect to={`${config("appRoot")}/login`} />;
  return null;
};

export default LoginRequired;
