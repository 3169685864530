import React, { useEffect, useRef, useState } from "react";
import Toggle from "../../components/toggle/toggle";
import { callDebounced } from "../../utils/debounce";
import { useDrag } from "../../utils/useDrag";
import styles from "./controlButton.module.css";
import bulb from "./bulb.svg";
import motion from "./motion.png";

export interface LightStatus {
  on: boolean;
  hue: number; // hue = 0-1 // 0 - 360
  sat: number; // sat = 0-1 // 0 - 100
  temp: number; // temp = 0-1 // 153-500
  brightness: number; // brightness = 0-1 // 0 - 255
}

interface ControlButtonProps {
  status?: Partial<LightStatus>;
  onChange: { (status: Partial<LightStatus>): unknown };
  name: string;
  color?: boolean;
  dimmer?: boolean;

  hasMotion?: boolean;
  hasAutomation?: boolean;
  onChangeAutomation?: { (): unknown };
}
export const ControlButton = (props: ControlButtonProps) => {
  const status = props.status || {};
  const [on, setOn] = useState(status.on !== undefined ? status.on : false);
  const [hue, setHue] = useState(status.hue !== undefined ? status.hue : 0);
  const [brightness, setBrightness] = useState(
    status.brightness !== undefined ? status.brightness : status.on ? 1 : 0
  );
  const [saturation, setSaturation] = useState(
    status.sat !== undefined ? status.sat : 1
  );

  useEffect(() => {
    if (status.on !== undefined) setOn(status.on);
  }, [status.on]);
  useEffect(() => {
    if (status.brightness !== undefined) setBrightness(status.brightness);
  }, [status.brightness]);
  useEffect(() => {
    if (status.hue !== undefined) setHue(status.hue);
  }, [status.hue]);
  useEffect(() => {
    if (status.sat !== undefined) setSaturation(status.sat);
  }, [status.sat]);

  const containerRef = useRef<HTMLDivElement>(null);
  useDrag(containerRef, (evt, pos) => {
    if (!props.dimmer) return;
    const brightness = Math.min(Math.max(1 - pos.y, 0), 1);
    setBrightness(brightness);
    setOn(brightness > 0);
    if (evt === "up") {
      props.onChange({
        brightness,
        on: brightness > 0,
      });
    }
  });

  const adjBrightness = on ? brightness : 0;

  const toggleOn = () => {
    const status = {
      on: !on,
      brightness: on ? 0 : 1,
    };
    setOn(status.on);
    setBrightness(status.brightness);
    props.onChange(status);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        onClick={(e) => {
          if (props.dimmer) return;
          e.stopPropagation();
          toggleOn();
        }}
        className={styles.fill}
        style={{
          backgroundPosition: `${Math.round(
            adjBrightness * 1000
          )}% ${Math.round(adjBrightness * (29 + 53) - 53)}%`,
        }}
      // className={styles.overlay}
      // onClick={props.onClose}
      >
        <div className={styles.content}>
          <img
            className={styles.bulb}
            src={bulb}
            onClick={(e) => {
              e.stopPropagation();
              toggleOn();
            }}
          />
          <span>
            {Math.round(adjBrightness * 100)}%<br /> {props.name}
          </span>
        </div>
      </div>

      {props.hasMotion !== undefined ? (
        <div className={styles.automation}>
          <img
            src={motion}
            alt="motion"
            style={{
              opacity: props.hasMotion ? 1 : 0.3,
            }}
          />
        </div>
      ) : null}
      {props.hasAutomation !== undefined ? (
        <div
          className={styles.automation}
          onClick={(e) => {
            e.stopPropagation();
            if (props.onChangeAutomation) props.onChangeAutomation();
          }}
        >
          <span role="img" aria-label="cancelMotion">
            {props.hasAutomation ? "" : "🚫"}
          </span>
        </div>
      ) : null}
    </div>
  );
};
