import React from "react";
import styles from "./settingsRow.module.css";

interface PropTypes {
  description: React.Component | string;
  input: any;
}

const SettingsRow: React.FC<PropTypes> = props => {
  return (
    <div className={styles.row}>
      <div className={styles.inputDescription}>{props.description}</div>
      <div className={styles.inputField}>{props.input}</div>
    </div>
  );
};

export default SettingsRow;
