import React, { useState, useEffect, useMemo, useCallback } from "react";
import Header from "../../components/header/header";
import styles from "./devices.module.css";
import deviceProvider, {
  DeviceData,
  DevicesType,
} from "../../providers/deviceProvider";
import house from "./house.png";
import house2 from "./house2.png";
import doorOpen from "./door_open.png";
import doorClose from "./door_close.png";
import motion from "./motion.png";
import {
  toSnakeCase,
  toTitleCase,
  removeUnderscores,
} from "../../utils/stringFormatting";
import AlarmSettings from "../../components/alarmSettings/alarmSettings";
import fastPropertyProvider from "../../providers/fastPropertyProvider";
import { ColorLight } from "./colorLight";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { useVisibility } from "../../utils/useVisibility";
import { usePolling } from "../../utils/usePolling";
import { ControlButton } from "./controlButton";
import { dedupe } from "../../utils/dedupe";
import storageHelper from "../../utils/storageHelper";
import { render } from "react-dom";
import moment from "moment";
import { ControlDoorButton } from "./controlDoor";

const formatDeviceId = (str: string) =>
  toTitleCase(removeUnderscores(toSnakeCase(str)));

const Devices: React.FC = () => {
  const [devices, devicesSet] = useState(null as DevicesType | null);
  const [deviceLocations, deviceLocationsSet] = useState(null as any);
  const [properties, propertiesSet] = useState(null as any);
  const [needsFetch, needsFetchSet] = useState(true);
  const [lightSettingsOpen, setLightSettingsOpen] = useState<
    keyof DevicesType | null
  >(null);

  // Object.keys(deviceLocations || {}).forEach((key) => {
  //   ((devices as any) || {})[key] = ((devices as any) || {})[key] || false;
  // });
  // console.log(devices);

  // initial data load
  if (needsFetch) {
    needsFetchSet(false);
    // deviceProvider.update();
    deviceProvider.getAll(0).then(devicesSet);
    deviceProvider.getLocations().then(deviceLocationsSet);
    fastPropertyProvider.get().then(propertiesSet);
  }

  // polling for new status
  usePolling(
    useCallback(() => {
      deviceProvider.getAll(0).then(devicesSet);
    }, []),
    5000
  );

  // pollinng for new properties
  usePolling(
    useCallback(() => {
      fastPropertyProvider.get().then(propertiesSet);
    }, []),
    60 * 60 * 1000
  );

  // setter for devices
  const setValue = (id: keyof DevicesType, status: boolean) => {
    if (!devices) return;
    deviceProvider.put(id, { status: { on: status, brightness: 1 } });
    const newValues = { ...devices };
    newValues[id].status.on = status;
    devicesSet(newValues);
  };

  // setter for properties
  const setProperty = (id: string, status: boolean) => {
    if (!properties) return;
    const updatedProperties = { ...properties, [id]: status };
    fastPropertyProvider.put(updatedProperties);
    propertiesSet(updatedProperties);
  };

  let peopleCount = 0;

  const areaList = dedupe([
    ...Object.keys(deviceLocations || {}).map(
      (key) => deviceLocations[key].area || "unknown"
    ),
    "unknown",
    "map",
  ]);

  const [areasDisabled, setAreasDisabled] = useState<string[]>(
    storageHelper.get("areasDisabled") || []
  );
  useEffect(() => {
    storageHelper.set("areasDisabled", areasDisabled);
  }, [areasDisabled]);

  const renderedItems = new Set<string>();

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        {/* {devices && devices.alarm ? (
          <AlarmSettings mode={devices.alarm} />
        ) : null} */}
        <div style={{ padding: "0 10px" }}>
          {areaList.map((area) => {
            if (areasDisabled.indexOf(area) !== -1) return null;
            return [
              <h1 key={area}>{formatDeviceId(area)}</h1>,
              ["light", "switch", "motionSensor", "contactSensor", "motionLights", "sleepMode", 'doorController'].map(
                (currentDeviceType) => (
                  <>
                    {Object.keys(devices || {}).map((deviceId) => {
                      if (!devices) return null;
                      if (renderedItems.has(deviceId)) return null;
                      const device = devices[deviceId];
                      if (!device) return null;
                      if (!deviceLocations) return null;
                      const location = deviceLocations[deviceId] || {};
                      location.area = location.area || "unknown";
                      if (location.area !== area) return null;
                      if (device.type !== currentDeviceType) return null;

                      if (device.type === 'doorController') {
                        renderedItems.add(deviceId);
                        return (
                          <ControlDoorButton
                            key={device.name}
                            name={location.name || formatDeviceId(device.name)}
                            status={device.status}
                            onChange={(status) => {
                              deviceProvider.put(device.name, { status });
                            }}
                          />
                        );
                      }

                      if (device.type === "light") {
                        renderedItems.add(deviceId);
                        return (
                          <ControlButton
                            key={device.name}
                            name={location.name || formatDeviceId(device.name)}
                            status={device.status}
                            // color
                            dimmer={
                              device.capabilities.indexOf("brightness") !== -1
                            }
                            onChange={(status) => {
                              deviceProvider.put(device.name, { status });
                            }}
                          />
                        );
                      }

                      if (device.type === "switch") {
                        renderedItems.add(deviceId);
                        return (
                          <ControlButton
                            key={device.name}
                            name={location.name || formatDeviceId(device.name)}
                            status={device.status}
                            onChange={(status) => {
                              deviceProvider.put(device.name, { status });
                            }}
                          />
                        );
                      }

                      if (device.type === "motionSensor") {
                        renderedItems.add(deviceId);
                        return (
                          <div
                            key={device.name}
                            className={styles.motionButton}
                          >
                            <img
                              src={motion}
                              alt="motion"
                              style={{
                                opacity: device.status.motion ? 1 : 0.3,
                              }}
                            />
                            <span>
                              {location.name || formatDeviceId(device.name)}
                              {(device.status.lastMotion || device.status.lastOpen) ? (<>
                                <br /><p style={{ display: 'inline', fontSize: 12 }}>{moment(device.status.lastMotion || device.status.lastOpen).fromNow()}</p>
                              </>) : null}
                              {device.status.battery ? (<>
                                <br /><p style={{ display: 'inline', fontSize: 12 }}>Battery {device.status.battery}%</p>
                              </>) : null}
                            </span>
                          </div>
                        );
                      }
                      if (device.type === "contactSensor") {
                        renderedItems.add(deviceId);
                        return (
                          <div
                            key={device.name}
                            className={styles.motionButton}
                          >
                            <img
                              src={device.status.open ? doorOpen : doorClose}
                              alt="contact"
                              style={{
                                opacity: device.status.open ? 1 : 0.3,
                              }}
                            />
                            <span>
                              {location.name || formatDeviceId(device.name)}
                              {device.lastUpdated ? (<>
                                <br /><p style={{ display: 'inline', fontSize: 12 }}>{moment(device.lastUpdated).fromNow()}</p>
                              </>) : null}
                              {device.status.battery ? (<>
                                <br /><p style={{ display: 'inline', fontSize: 12 }}>Battery {device.status.battery}%</p>
                              </>) : null}
                            </span>
                          </div>
                        );
                      }
                      return null;
                    })}
                    {Object.keys(properties || {}).map((deviceId) => {
                      if (!properties) return null;
                      if (!deviceLocations) return null;
                      if (renderedItems.has(deviceId)) return null;

                      const location = deviceLocations[deviceId];
                      const status = properties[deviceId];
                      if (!location) return null;

                      if (location.area !== area) return null;

                      if (location.type !== currentDeviceType) return null;

                      if (location.type === "motionLights") {
                        renderedItems.add(deviceId);
                        return (
                          <div
                            className={styles.genericButton}
                            key={deviceId}
                            onClick={() => setProperty(deviceId, !status)}
                          >
                            <span role="img" aria-label="cancelMotion">
                              {status ? "✅" : "🚫"}
                            </span>
                            <span className={styles.content}>
                              {location.name || formatDeviceId(deviceId)}
                            </span>
                          </div>
                        );
                      }
                      if (location.type === "sleepMode") {
                        renderedItems.add(deviceId);
                        return (
                          <div
                            className={styles.genericButton}
                            key={deviceId}
                            onClick={() => setProperty(deviceId, !status)}
                          >
                            <span role="img" aria-label="sleepMode">
                              {status ? "🌙" : "☀️"}
                            </span>
                            <span className={styles.content}>
                              {location.name || formatDeviceId(deviceId)}
                            </span>
                          </div>
                        );
                      }

                      return null;
                    })}
                  </>
                )
              ),
            ];
          })}
        </div>
        {areasDisabled.indexOf("map") === -1 ? (
          <div className={styles.diagram}>
            <img className={styles.img} src={house} alt="home" />
            <img className={styles.img} src={house2} alt="home" />
            {Object.keys(devices || {}).map((id) => {
              if (!devices) return null;
              if (!deviceLocations) return null;

              const deviceId = id;
              const device = deviceLocations[deviceId];
              const deviceData = devices[deviceId];
              const status = deviceData.status.on || deviceData.status.motion;
              if (!device) return null;

              // if (device.type === "person") {
              //   peopleCount++;
              //   return (
              //     <div
              //       key={deviceId}
              //       className={styles.people}
              //       style={{
              //         left: 280,
              //         top: 20 + peopleCount * 20,
              //         opacity: status !== "away" ? 1 : 0.3,
              //       }}
              //     >
              //       {formatDeviceId(deviceId)}
              //     </div>
              //   );
              // }
              if (device.type === "lightColor") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                    onClick={() => setLightSettingsOpen(deviceData.name)}
                  >
                    <span
                      role="img"
                      aria-label="light"
                      style={{ opacity: status ? 1 : 0.3 }}
                    >
                      💡
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              if (device.type === "lightDimmable") {
                // return (
                //   <div
                //     key={deviceId}
                //     className={styles.device}
                //     style={{
                //       left: device.x,
                //       top: device.y,
                //     }}
                //     onClick={() => setValue(deviceId, !status)}
                //   >
                //     <span
                //       role="img"
                //       aria-label="light"
                //       style={{ opacity: status ? 1 : 0.3 }}
                //     >
                //       💡
                //     </span>
                //     <div className={styles.tooltip}>
                //       {formatDeviceId(deviceId)}
                //     </div>
                //   </div>
                // );
              }
              if (device.type === "light") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                    onClick={() => {
                      setValue(deviceId, !status);
                      const hasBrightness =
                        deviceData.capabilities.indexOf("brightness") !== -1;
                      if (hasBrightness) {
                        setLightSettingsOpen(deviceData.name);
                      }
                    }}
                  >
                    <span
                      role="img"
                      aria-label="light"
                      style={{ opacity: status ? 1 : 0.3 }}
                    >
                      💡
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              if (device.type === "speaker") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                    onClick={() => setValue(deviceId, !status)}
                  >
                    <span
                      role="img"
                      aria-label="speaker"
                      style={{ opacity: status ? 1 : 0.3 }}
                    >
                      🔊
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              if (device.type === "siren") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                    onClick={() => {
                      if (
                        status ||
                        window.confirm(
                          "Are you sure you want to turn on the Siren?"
                        )
                      )
                        setValue(deviceId, !status);
                    }}
                  >
                    <span
                      role="img"
                      aria-label="siren"
                      style={{ opacity: status ? 1 : 0.3 }}
                    >
                      🚨
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              if (device.type === "door") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                  >
                    {status ? (
                      <img src={doorOpen} alt="door open" />
                    ) : (
                      <img
                        src={doorClose}
                        alt="door close"
                        style={{ opacity: 0.3 }}
                      />
                    )}
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              if (device.type === "motion") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                  >
                    <img
                      src={motion}
                      alt="motion"
                      style={{ opacity: status ? 1 : 0.3 }}
                    />
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }
              return null;
            })}
            {Object.keys(properties || {}).map((deviceId) => {
              if (!properties) return null;
              if (!deviceLocations) return null;

              const device = deviceLocations[deviceId];
              const status = properties[deviceId];
              if (!device) return null;

              if (device.type === "sleepMode") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                    onClick={() => setProperty(deviceId, !status)}
                  >
                    <span
                      role="img"
                      aria-label="sleepMode"
                      style={{ opacity: status ? 1 : 0.3 }}
                    >
                      😴
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }

              if (device.type === "motionLights") {
                return (
                  <div
                    key={deviceId}
                    className={styles.device}
                    style={{
                      left: device.x,
                      top: device.y,
                    }}
                  >
                    <span
                      role="img"
                      aria-label="cancelMotion"
                      style={{ opacity: status ? 0 : 1 }}
                      onClick={() => setProperty(deviceId, !status)}
                    >
                      🚫
                    </span>
                    <div className={styles.tooltip}>
                      {formatDeviceId(deviceId)}
                    </div>
                  </div>
                );
              }

              return null;
            })}
            <div />
          </div>
        ) : null}
      </div>
      <div style={{ height: 400 }} />
      <div style={{ width: 400, margin: "auto" }}>
        <h1>Show / Hide Areas</h1>
        {areaList.map((area) => {
          const enabled = areasDisabled.indexOf(area) === -1;
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (enabled) setAreasDisabled([...areasDisabled, area]);
                else setAreasDisabled(areasDisabled.filter((a) => a !== area));
              }}
            >
              {enabled ? "✅" : "🚫"} {formatDeviceId(area)}
            </div>
          );
        })}
      </div>
      <div style={{ height: 400 }} />
      {lightSettingsOpen && devices ? (
        <ColorLight
          name={devices[lightSettingsOpen].name}
          status={devices[lightSettingsOpen].status}
          // color
          dimmer
          onChange={(status) => {
            deviceProvider.put(lightSettingsOpen, { status });
          }}
          onClose={() => {
            setLightSettingsOpen(null);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Devices;
