import config from "../config";
import authHelper from "../utils/authHelper";

class FastPropertyProvider {
  async get(): Promise<any> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/fast_properties`, {
      headers: {
        Authorization: token
      }
    });
    const msg = await res.json();
    return msg;
  }

  async put(properties: any): Promise<any> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/fast_properties`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(properties)
    });
    const msg = await res.json();
    return msg;
  }
}

const fastPropertyProvider = new FastPropertyProvider();

export default fastPropertyProvider;
export { FastPropertyProvider };
