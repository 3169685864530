import React from "react";
import { Recipe, RecipeAmount } from "../models/cookbookModels";
import { getMealNutrition } from "../utils/recipeHelper";
import { RecipeCard } from "./RecipeCard";

import Input from "../../../components/input/input";

interface Props {
  title: string;
  recipes: RecipeAmount[];
  drag?: { (recipe: Recipe): any }; // for the Day Card
  drop?: { (): Recipe | null };
  onChangeRecipes?: { (newRecipes: RecipeAmount[]): unknown };
}

export const DayCard = ({ title, recipes, drag, drop, onChangeRecipes }: Props) => {
  const { protein, calories, veggies, carbs } = getMealNutrition(recipes);

  const onDrop = () => {
    if (!drop || !onChangeRecipes) return;
    const recipe = drop();
    if (!recipe) return;
    let quantity = 1;
    const newRecipes = recipes.filter(r => {
      const same = r.recipe.name === recipe.name;
      if (same) quantity += r.quantity;
      return !same;
    });
    newRecipes.push({ recipe, quantity });
    onChangeRecipes(newRecipes);
  }

  const onDeleteRecipe = (recipe: Recipe) => () => {
    if (!onChangeRecipes) return;
    const newRecipes = recipes.filter(r => r.recipe.name !== recipe.name);
    onChangeRecipes(newRecipes);
  }
  const onChangeQuantity = (recipe: Recipe) => (quantity: number | string) => {
    if (!onChangeRecipes) return;
    if (typeof quantity === 'string') return;
    const idx = recipes.findIndex(r => r.recipe.name === recipe.name);
    if (idx === -1) return;
    const newRecipes = [...recipes];
    newRecipes[idx] = { ...newRecipes[idx], quantity };
    onChangeRecipes(newRecipes);
  }


  return (
    <div style={{ display: 'inline-block', border: '1px solid black', margin: 10, padding: 10, width: 380, verticalAlign: 'top' }} onDrop={onDrop} onDragOver={(e) => e.preventDefault()}>
      <div><h3>{title}</h3></div>
      <div>{Math.round(calories || 0)}cal | {Math.round(protein || 0)}p |  {Math.round(veggies || 0)}v |  {Math.round(carbs || 0)}c</div>
      {recipes.map(r => (
        <div>
          <RecipeCard recipe={r.recipe} drag={drag} />
          <Input value={r.quantity} onChange={onChangeQuantity(r.recipe)} style={{
            verticalAlign: 'top',
            height: 60,
            marginTop: 10,
            marginRight: 5,
            textAlign: 'center',
          }} />
          <button onClick={onDeleteRecipe(r.recipe)} style={{
            verticalAlign: 'top',
            height: 60,
            marginTop: 10,
          }}>X</button>
        </div>
      ))}

    </div>
  )
}
