import React, { useRef, useState } from "react";
import styles from "./suggestInput.module.css";

interface Option<T> {
  label: string;
  value?: T;
}

interface PropTypes<T> {
  onChange: { (newVal: Option<T>): unknown };
  value: Option<T>;
  options: Option<T>[];
  style?: React.CSSProperties;
}


function SuggestInput<T>(props: PropTypes<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const options = props.options.filter(o => o.label.toLowerCase().includes(props.value.label.toLowerCase())).slice(0, 10);
  return (
    <span style={{ position: 'relative' }}>
      <input
        ref={inputRef}
        type="text"
        className={styles.container}
        style={props.style}
        onChange={e => props.onChange({ label: e.target.value })}
        value={props.value.label}
        aria-autocomplete='none'
        onFocus={() => setIsFocus(true)}
        onBlur={() => setTimeout(() => setIsFocus(false), 500)}
      />
      {isFocus ? (
        <div style={{ position: 'absolute', zIndex: 999, border: '1px solid black', background: 'white' }}>
          {options.map(o => {
            return <div className={styles.selectValue} onClick={() => {
              props.onChange(o);
              setIsFocus(false);
              if (inputRef && inputRef.current) inputRef.current.blur();
            }}>{o.label}</div>
          })}
        </div>
      ) : null}
    </span>
  );
};

export default SuggestInput;
