import React from "react";
import Header from "../../components/header/header";
import { isHighBandwidth } from "../../config";
import cameraProvider from "../../providers/cameraProvider";
import style from "./viewCameras.module.css";

interface ViewCamerasProps {}
interface ViewCamerasState {
  images: string[];
  totalCameras: number;
}

class ViewCameras extends React.Component<ViewCamerasProps, ViewCamerasState> {
  state = {
    images: [],
    totalCameras: 0,
  };
  mounted: boolean = false;
  async componentDidMount() {
    this.mounted = true;
    const totalCameras = await cameraProvider.getTotalCameras();
    if (!this.mounted) return;
    this.setState({ totalCameras });
    if (isHighBandwidth()) this.fastFetchImages();
    else this.fetchImages();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  fetchImage = async (i: number) => {
    try {
      const imageBlob = await cameraProvider.getCameraImage(i);
      const image = URL.createObjectURL(imageBlob);
      this.setState((state) => {
        const images = state.images.slice();
        images.splice(i, 1, image);
        return { images };
      });
    } catch (e) {}
  };
  fastFetchImages = async () => {
    for (let i = 0; i < this.state.totalCameras; i++) {
      const loop = async () => {
        if (!this.mounted) return null;
        await this.fetchImage(i);
        setTimeout(loop, 1000);
      };
      loop();
    }
  };
  fetchImages = async () => {
    for (let i = 0; i < this.state.totalCameras; i++) {
      if (!this.mounted) return null;
      await this.fetchImage(i);
    }
    setTimeout(this.fetchImages, 1000);
  };
  render() {
    const images = this.state.images;
    return (
      <React.Fragment>
        <Header />
        {/* <div
          style={{
            position: "relative",
            height: 264,
            top: 10,
            width: 400,
            margin: "auto",
          }}
        >
          <img
            style={{
              transform: "rotate(43deg)",
              position: "absolute",
              left: 11,
              top: 63,
              zIndex: 1,
              width: 200,
            }}
            alt="cam"
            src={images[2]}
          />
          <img
            style={{ position: "absolute", left: 146, width: 200 }}
            alt="cam"
            src={images[1]}
          />
          <img
            style={{
              transform: "rotate(148deg)",
              position: "absolute",
              left: 191,
              top: 80,
              zIndex: 1,
              width: 200,
            }}
            alt="cam"
            src={images[0]}
          />
        </div> */}
        <div style={{ textAlign: "center" }}>
          {this.state.images.map((image, idx) => (
            <img className={style.image} key={idx} alt="cam" src={image} />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default ViewCameras;
