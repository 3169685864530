import React from "react";
import style from "./login.module.css";
import authProvider from "../../providers/authProvider";
import authHelper from "../../utils/authHelper";
import { Redirect } from "react-router";
import config from "../../config";

interface LoginProps { }
interface LoginState {
  loggedIn: boolean;
  username: string;
  password: string;
  error: string;
}

class Login extends React.Component<LoginProps, LoginState> {
  state = {
    loggedIn: false,
    username: "",
    password: "",
    error: '',
  };
  login = async (e: React.FormEvent) => {
    e.preventDefault();
    const auth = await authProvider.login(
      this.state.username,
      this.state.password
    );
    if (auth.error) {
      this.setState({ error: auth.error });
    } else {
      this.setState({ error: '' });
      authHelper.setAuth(auth);
      this.setState({});
    }
    return;
  };
  render() {
    if (authHelper.getToken() !== null)
      return <Redirect to={config("appRoot")} />;
    return (
      <div className={style.container}>
        <form onSubmit={this.login}>
          <div className={style.header}>Login</div>
          <input
            placeholder="Username"
            type="text"
            name="username"
            onChange={e => this.setState({ username: e.target.value })}
          />
          <input
            placeholder="Password"
            type="password"
            name="password"
            onChange={e => this.setState({ password: e.target.value })}
          />
          <div style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</div>
          <input className={style.submit} type="submit" name="submit" />
        </form>
      </div>
    );
  }
}

export default Login;
