import React from "react";
import Header from "../../components/header/header";

import { Ingredient, MealSchedule, Recipe } from "./models/cookbookModels";

import { useAsync } from "../../utils/useAsync";
import { normalizeRecipe, recipeProvider } from "./providers/recipeProvider";
import { ingredientProvider, normalizeIngredient } from "./providers/ingredientsProvider";
import { mealScheduleProvider, normalizeMealSchedule } from "./providers/mealScheduleProvider";
import { useAsyncAsLocal } from "../../utils/useAsyncAsLocal";
import { CookbookMenu } from "./components/CookbookMenu";

export const CookbookAdmin: React.FC = () => {

  const [allRecipes] = useAsync(recipeProvider.getAll, [], [] as Recipe[]);
  const [allIngredients] = useAsync(ingredientProvider.getAll, [], [] as Ingredient[]);
  const [mealSchedule] = useAsyncAsLocal(mealScheduleProvider.getAll, mealScheduleProvider.update, {} as MealSchedule);

  return (
    <React.Fragment>
      <Header />
      <CookbookMenu currentPage="admin" />
      <textarea value={JSON.stringify(allRecipes.map(normalizeRecipe), null, 2)} />
      <textarea value={JSON.stringify(allIngredients.map(normalizeIngredient), null, 2)} />
      <textarea value={JSON.stringify(normalizeMealSchedule(mealSchedule), null, 2)} />
      <button onClick={() => {
        recipeProvider.resetDB();
        ingredientProvider.resetDB();
        mealScheduleProvider.resetDB();
      }}>Reset DB</button>

    </React.Fragment>
  );
};
