import { MealSchedule, Recipe } from '../models/cookbookModels';
import storageHelper from '../../../utils/storageHelper';
import { deepCopy } from '../../../utils/deepCopy';
import { objectKeys } from '../../../utils/objectKeys';
import { recipeProvider } from './recipeProvider';
import config from '../../../config';
import authHelper from '../../../utils/authHelper';
import { cacheFn } from '../../../utils/cacheFn';

export const denormalizeMealSchedule = (mealSchedule: MealSchedule, recipeMap: { [id: string]: Recipe }) => {
  mealSchedule = deepCopy(mealSchedule);
  objectKeys(mealSchedule).forEach(day => {
    mealSchedule[day] = mealSchedule[day].map(meal => ({ recipe: recipeMap[meal.recipe.id], quantity: meal.quantity }));
  })
  return mealSchedule;
}

export const normalizeMealSchedule = (mealSchedule: MealSchedule) => {
  mealSchedule = deepCopy(mealSchedule);
  objectKeys(mealSchedule).forEach(day => {
    mealSchedule[day] = mealSchedule[day].map(meal => ({ recipe: { id: meal.recipe.id, name: meal.recipe.name }, quantity: meal.quantity }));
  })
  return mealSchedule;
}

export const mealScheduleProvider = {
  getAll: cacheFn(async () => {
    const recipes = await recipeProvider.getAll();
    const recipesMap: { [id: string]: Recipe } = {};
    recipes.forEach(recipe => { recipesMap[recipe.id || ''] = recipe; });

    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/meal_schedule`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    });
    const mealSchedule = await res.json() as MealSchedule;

    return denormalizeMealSchedule(mealSchedule, recipesMap);
  }, 500),
  update: async (mealSchedule: MealSchedule): Promise<MealSchedule> => {
    mealSchedule = normalizeMealSchedule(mealSchedule);
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/cookbook/meal_schedule`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(mealSchedule, null, 4),
    });
    mealSchedule = await res.json() as MealSchedule;
    return mealSchedule;
  },
  resetDB: () => { },
}