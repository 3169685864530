import config, { ConfigEnv } from "../config";
import authHelper from "../utils/authHelper";

class HealthProvider {
  async healthcheck(env?: ConfigEnv): Promise<string> {
    // const token = authHelper.getToken();
    // if (token === null) throw new Error("UNAUTHORIZED");
    const res = fetch(`${config("serverPath", env)}/healthcheck`, {
      // headers: { Authorization: token }
    }).then((res) => res.text());
    return Promise.race([
      res,
      new Promise<string>((_, reject) =>
        setTimeout(() => reject(new Error("TIMEOUT")), 5000)
      ),
    ]);
  }
  async reboot() {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/system/reboot`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
  }
}

const healthProvider = new HealthProvider();

export default healthProvider;
export { HealthProvider };
