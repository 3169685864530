import { getDeep } from './getDeep';

export function dedupe<T>(items: T[], field?: string): T[] {
  const dupes: { [key: string]: boolean } = {};
  return items.filter((item) => {
    let key = `${item}`;
    if (field) key = getDeep(item, field);
    if (dupes[key]) return false;
    dupes[key] = true;
    return true;
  });
}
