import React, { useEffect, useState } from "react";

interface Props {
  imageUrl?: string;
  onUpload?: { (data: string): unknown }
}
export const ImageUpload = ({ imageUrl, onUpload }: Props) => {
  const [img, setImg] = useState(imageUrl);
  useEffect(() => setImg(imageUrl), [imageUrl]);

  async function handleFile(file: any) {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      const data = fr.result;
      if (typeof data === 'string') {
        setImg(data);
        onUpload && onUpload(data);
      }
    }

  }
  return (
    <div style={{ border: '1px solid black', width: 200, height: 100 }}>
      <input
        type="text"
        onPaste={e => {
          const { clipboardData } = e;
          Array.prototype.forEach.call(clipboardData.types, function (type: string, i: number) {
            var file, reader;
            if (
              type.match(/image.*/) ||
              e.clipboardData.items[i].type.match(/image.*/)
            ) {
              file = clipboardData.items[i].getAsFile();
              handleFile(file);
            }
          });
        }}
        value={'Paste Here'}
      />
      <input type="file" onChange={(e) => handleFile(e.target.files && e.target.files[0])} />
      {img ? <img src={img} style={{ width: 50, height: 50, objectFit: 'cover' }} /> : null}
      {img ? <button onClick={() => onUpload && onUpload('')}>x</button> : null}
    </div>
  );
}