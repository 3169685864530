import React, { useState } from "react";
import Header from "../../components/header/header";

import { Ingredient, MealSchedule, Recipe } from "./models/cookbookModels";

import { mealScheduleToIngredientsList, getIngredientNutrition, setAllIngredientsForRecipes } from "./utils/recipeHelper";
import { useAsync } from "../../utils/useAsync";
import { ingredientProvider, normalizeIngredient } from "./providers/ingredientsProvider";
import { mealScheduleProvider, normalizeMealSchedule } from "./providers/mealScheduleProvider";
import { useAsyncAsLocal } from "../../utils/useAsyncAsLocal";
import { CookbookMenu } from "./components/CookbookMenu";


export const ShoppingList: React.FC = () => {

  const [allIngredients] = useAsync(ingredientProvider.getAll, [], [] as Ingredient[]);
  const [mealSchedule] = useAsyncAsLocal(mealScheduleProvider.getAll, mealScheduleProvider.update, {} as MealSchedule);
  if (allIngredients) setAllIngredientsForRecipes(allIngredients);

  const ingredients = mealScheduleToIngredientsList(mealSchedule);

  const ingredientTypes = Array.from(new Set(ingredients.reduce((accum, item) => [...accum, item.item.type || 'other'], [] as string[])));
  ingredientTypes.sort();

  return (
    <React.Fragment>
      <Header />
      <CookbookMenu currentPage="shopping" />

      <h2>Shopping List</h2>
      <div style={{
        display: 'inline-block',
        textAlign: 'left'
      }}>
        <table>
          <tbody>
            {ingredientTypes.map(type => (
              <>
                <tr>
                  <td colSpan={3}><h3>{type || 'unknown'}</h3></td>
                </tr>
                {ingredients.filter(item => (item.item.type || 'other') === type).map(ingredient => (
                  <tr>
                    <td>{ingredient.item.name}</td>
                    <td>{Math.round(ingredient.quantity * 10) / 10}</td>
                    <td>{ingredient.item.unit}</td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>

    </React.Fragment>
  );
};
