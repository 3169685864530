import React, { useState } from "react";
import Header from "../../components/header/header";
import style from "./cookbook.module.css";

import { Ingredient, MealSchedule, Recipe } from "./models/cookbookModels";

import { useDragDrop } from "../../utils/useDragDrop";
import { mealScheduleToIngredientsList, getIngredientNutrition, setAllIngredientsForRecipes } from "./utils/recipeHelper";
import { RecipeCard } from "./components/RecipeCard";
import { DayCard } from "./components/DayCard";
import { useAsync } from "../../utils/useAsync";
import { normalizeRecipe, recipeProvider } from "./providers/recipeProvider";
import { ingredientProvider, normalizeIngredient } from "./providers/ingredientsProvider";
import Modal from "../../components/modal/modal";
import { RecipeForm } from "./components/RecipeForm";
import Button from "../../components/button/button";
import { mealScheduleProvider, normalizeMealSchedule } from "./providers/mealScheduleProvider";
import { useAsyncAsLocal } from "../../utils/useAsyncAsLocal";
import { CookbookMenu } from "./components/CookbookMenu";



const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const meals = ['🥪 Lunch', '🍽️ Dinner'];

export const MealPlanning: React.FC = () => {

  const [editingRecipe, setEditingRecipe] = useState<Recipe | null | false>(false);

  const [allRecipes, , refreshAllRecipes] = useAsync(recipeProvider.getAll, [], [] as Recipe[]);
  const [allIngredients] = useAsync(ingredientProvider.getAll, [], [] as Ingredient[]);
  const [mealSchedule, setMealSchedule] = useAsyncAsLocal(mealScheduleProvider.getAll, mealScheduleProvider.update, {} as MealSchedule);
  if (allIngredients) setAllIngredientsForRecipes(allIngredients);

  const [drag, drop] = useDragDrop<Recipe>();

  const ingredients = mealScheduleToIngredientsList(mealSchedule);

  let totalCalories = 0;
  let totalProtein = 0;
  ingredients.forEach(ingredient => {
    const { protein, calories } = getIngredientNutrition(ingredient);
    totalCalories += calories;
    totalProtein += protein;
  })
  const totalMeals = totalCalories / 700;

  return (
    <React.Fragment>
      <Header />
      <CookbookMenu currentPage="planning" />
      {editingRecipe !== false ? (
        <Modal>
          <RecipeForm
            recipe={editingRecipe}
            onCancel={() => setEditingRecipe(false)}
            onSave={async (recipe) => {
              // add any missing ingredients
              for (let i = 0; i < (recipe.ingredients || []).length; i++) {
                let ingredient = (recipe.ingredients || [])[i].item;
                if (!ingredient.id) {
                  ingredient = await ingredientProvider.add(ingredient);
                  (recipe.ingredients || [])[i].item = ingredient;
                }
              }

              // add the recipe
              if (recipe.id) await recipeProvider.update(recipe)
              else await recipeProvider.add(recipe);

              // update the page
              refreshAllRecipes();
              setEditingRecipe(false);
            }}
          />

        </Modal>
      ) : null}
      <div className={style.container}>
        <h1>Cookbook</h1>

        <Button onClick={() => setEditingRecipe(null)}>Add Recipe</Button>
        <div style={{
          display: 'inline-block',
          textAlign: 'left'
        }}>
          {(allRecipes || []).map((recipe) => {
            return <RecipeCard key={recipe.id} recipe={recipe} drag={drag} onEdit={() => setEditingRecipe(recipe)} />
          })}
        </div>

        <br /><br />
        <hr />
        <br /><br />

        <div style={{
          display: 'inline-block',
          textAlign: 'left'
        }}>
          {days.flatMap((day) => meals.map(meal => {
            const dayKey = day + ' ' + meal;
            return <DayCard
              key={dayKey}
              title={dayKey}
              recipes={mealSchedule[dayKey] || []}
              drag={drag}
              drop={drop}
              onChangeRecipes={async (newRecipes) => {
                setMealSchedule({ ...mealSchedule, [dayKey]: newRecipes });
              }}
            />
          }))}
        </div>

        <h2>Schedule</h2>
        <div style={{
          display: 'inline-block',
          textAlign: 'left'
        }}>
          <table>
            <tbody>
              <tr>
                <td></td>
                <td>Sun</td>
                <td>Mon</td>
                <td>Tue</td>
                <td>Wed</td>
                <td>Thu</td>
                <td>Fri</td>
                <td>Sat</td>
              </tr>
              <tr>
                <td>Lunch</td>
                <td>0</td>
                <td style={{ background: 'cyan' }}>1</td>
                <td style={{ background: 'cyan' }}>2</td>
                <td style={{ background: 'yellow' }}>1</td>
                <td style={{ background: 'yellow' }}>1</td>
                <td style={{ background: 'yellow' }}>2</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Dinner</td>
                <td style={{ background: 'cyan' }}>2</td>
                <td style={{ background: 'cyan' }}>2</td>
                <td style={{ background: 'yellow' }}>2</td>
                <td style={{ background: 'yellow' }}>2</td>
                <td style={{ background: 'yellow' }}>2</td>
                <td>2</td>
                <td>2</td>
              </tr>

              <tr>
                <td>Meals</td>
                <td style={{ background: 'cyan' }}>7</td>
                <td></td>
                <td style={{ background: 'yellow' }}>10</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div>
            Total Calories: {Math.round(totalCalories)}
          </div>
          <div>
            Meals: {Math.round(totalMeals * 10) / 10}
          </div>
          <div>
            Protein: {Math.round(totalProtein)} (per meal: {Math.round(totalProtein / totalMeals)})
          </div>
          calories or  meals
        </div>

      </div>

    </React.Fragment>
  );
};
