import { useRef, useEffect, RefObject } from "react";

export const useDrag = (
  containerRef: RefObject<HTMLDivElement | null>,
  onChange: { (evt: "move" | "up", pos: { x: number; y: number }): unknown }
) => {
  const lastPos = useRef({
    prevX: 0,
    prevY: 0,
    startX: 0,
    startY: 0,
    startCanvas: "",
    dragging: false,
    hasMoved: false,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    function mouseEvent(res: string, e: MouseEvent | TouchEvent) {
      if (!container) return;
      let curX = 0;
      let curY = 0;
      if ((e as any).touches) {
        const touch = (e as TouchEvent).touches[0];
        if (touch) {
          curX = touch.clientX - container.offsetLeft + window.scrollX;
          curY = touch.clientY - container.offsetTop + window.scrollY;
        } else {
          curX = lastPos.current.prevX;
          curY = lastPos.current.prevY;
        }
      } else {
        const mouse = e as MouseEvent;
        curX = mouse.clientX - container.offsetLeft + window.scrollX;
        curY = mouse.clientY - container.offsetTop + window.scrollY;
      }
      lastPos.current.prevX = curX;
      lastPos.current.prevY = curY;

      if (res === "up" || res === "out") {
        if (lastPos.current.dragging) {
          onChange("up", {
            x: curX / container.offsetWidth,
            y: curY / container.offsetHeight,
          });
        }
        lastPos.current.dragging = false;
      } else if (res === "down") {
        // console.log(e.target, container, e.target === container);
        if (e.target === container) {
          lastPos.current.dragging = true;
          lastPos.current.startX = curX;
          lastPos.current.startY = curY;
          lastPos.current.hasMoved = false;
        }
      } else if (res === "move" && lastPos.current.dragging) {
        lastPos.current.hasMoved = true;
        onChange("move", {
          x: curX / container.offsetWidth,
          y: curY / container.offsetHeight,
        });
      }
    }
    const move = (e: MouseEvent | TouchEvent) => mouseEvent("move", e);
    const down = (e: MouseEvent | TouchEvent) => mouseEvent("down", e);
    const up = (e: MouseEvent | TouchEvent) => mouseEvent("up", e);

    document.body.addEventListener("touchstart", down, false);
    document.body.addEventListener("touchend", up, false);
    document.body.addEventListener("touchmove", move, false);

    document.body.addEventListener("mousemove", move, false);
    document.body.addEventListener("mousedown", down, false);
    document.body.addEventListener("mouseup", up, false);
    // document.body.addEventListener("mouseout", up, false);

    return () => {
      document.body.removeEventListener("touchstart", down);
      document.body.removeEventListener("touchend", up);
      document.body.removeEventListener("touchmove", move);

      document.body.removeEventListener("mousemove", move);
      document.body.removeEventListener("mousedown", down);
      document.body.removeEventListener("mouseup", up);
      // document.body.removeEventListener("mouseout", up);
    };
  }, []);
};
