import config from "../config";
import authHelper from "../utils/authHelper";

export type Device =
  'toto_stop' |
  'toto_butt_spray_wide' |
  'toto_butt_spray_pointy' |
  'toto_female_spray_pointy' |
  'toto_female_spray_wide' |
  'toto_blow' |
  'toto_front_and_back' |
  'toto_pulsate_pressure' |
  'toto_clean' |
  'toto_strength_1' |
  'toto_strength_2' |
  'toto_strength_3' |
  'toto_strength_4' |
  'toto_strength_5' |
  'toto_front_5' |
  'toto_front_4' |
  'toto_front_3' |
  'toto_front_2' |
  'toto_front_1' |
  'toto_person_1' |
  'toto_person_2' |
  'toto_flush_hard' |
  'toto_flush_soft' |
  'toto_open_lid';

class SwitchProvider {
  async activateSwitch(device: Device, on = true) {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    await fetch(`${config("serverPath")}/api/switch/${device}${on ? '' : '/off'}`, {
      method: "POST",
      headers: {
        Authorization: token
      }
    });
  }
}

const switchProvider = new SwitchProvider();

export default switchProvider;
export { SwitchProvider };
