import React, { useEffect, useState } from "react";
import styles from "./input.module.css";

interface PropTypes<T extends string | number | object> {
  onChange: { (newVal: T): unknown };
  value: T;
  isTextArea?: boolean;
  style?: React.CSSProperties;
}



function Input<T extends string | number | object>({ value, onChange, style, isTextArea }: PropTypes<T>) {
  const [localValue, setLocalValue] = useState('');
  const [invalid, setInvalid] = useState(false);
  useEffect(() => setLocalValue(serialize(value)), [value]);

  const localOnChange = (v: string) => {
    if (typeof value === 'number') {
      setLocalValue(v);
      const parsed = parseFloat(v);
      if (parsed) {
        onChange(parsed as T);
        setInvalid(false);
      } else {
        setInvalid(true);
      }

    } else if (typeof value === 'object') {
      setLocalValue(v);
      try {
        const parsed = JSON.parse(v);
        if (parsed) onChange(parsed as T);
        setLocalValue(serialize(parsed));
        setInvalid(false);
      } catch (e) {
        setInvalid(true);
      }
    } else { // string
      onChange(v as T);
      setLocalValue(v);
      setInvalid(false);
    }
  }

  const serialize = (v: T): string => {
    if (typeof value === 'number') {
      return `${v}`
    } else if (typeof value === 'object') {
      return JSON.stringify(v, null, 2);
    } else { // string
      return v as string;
    }
  }

  if (isTextArea) return (
    <textarea
      className={styles.container}
      style={{ border: invalid ? '1px solid red' : undefined, ...(style || {}) }}
      onChange={e => localOnChange(e.target.value)}
      value={localValue}
      aria-autocomplete='none'
      autoComplete="none"
    />
  );

  return (
    <input
      type="text"
      className={styles.container}
      style={{ border: invalid ? '1px solid red' : undefined, ...(style || {}) }}
      onChange={e => localOnChange(e.target.value)}
      value={localValue}
      aria-autocomplete='none'
      autoComplete="none"
    />
  );
};

export default Input;
