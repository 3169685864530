import config from "../config";
import { LightStatus } from "../containers/devices/colorLight";
import authHelper from "../utils/authHelper";

type PersonLocation = "close" | "home" | "away";
type UserAlarmMode = "all" | "some" | "none";
type AlarmMode = UserAlarmMode | "exit_delay" | null;

export interface DeviceData {
  name: string;
  type: string;
  lastUpdated: number;
  capabilities: string[];
  controller: string;
  status: {
    on?: boolean;
    motion?: boolean;
    open?: boolean;
    battery?: number;
    lastMotion?: number;
    lastOpen?: number;
  };
}

export interface DevicesType {
  [deviceName: string]: DeviceData;
}

class DeviceProvider {
  async getAll(time: number): Promise<DevicesType> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(
      `${config("serverPath")}/api/devices/recent/${time}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const msg = await res.json();
    return msg;
  }

  async getHistory(device: string): Promise<{ value: number; time: string }[]> {
    return null as any;
    // const token = authHelper.getToken();
    // if (token === null) throw new Error("UNAUTHORIZED");
    // const res = await fetch(
    //   `${config("serverPath")}/api/all_devices/${device}/history`,
    //   {
    //     headers: {
    //       Authorization: token,
    //     },
    //   }
    // );
    // const msg = await res.json();
    // return msg;
  }

  async getLocations(): Promise<any> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(
      `${config("serverPath")}/api/all_devices/locations`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const msg = await res.json();
    return msg;
  }
  async update(): Promise<any> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/all_devices/update`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    const msg = await res.json();
    return msg;
  }

  async put(
    id: keyof DevicesType,
    status: Object
  ): Promise<{ status: boolean }> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/devices/${id}`, {
      method: "PUT",
      body: JSON.stringify(status),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const msg = await res.json();
    return msg;
  }

  async putLightStatus(
    id: keyof DevicesType,
    status: Partial<LightStatus>
  ): Promise<{ status: boolean }> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/all_devices/${id}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(status),
    });
    const msg = await res.json();
    return msg;
  }
}

const deviceProvider = new DeviceProvider();

export default deviceProvider;
export { DeviceProvider };
