/**
 *
 * DNS artem.redirectme.net for 24.7.88.154
 * https://my.noip.com/#!/dynamic-dns
 *
 * SSL Cert for artem.redirectme.net
 * https://www.sslforfree.com/certificates
 *
 * Port forwarding for 443 to 192.168.50.253:8084
 * http://192.168.50.1/Advanced_VirtualServer_Content.asp
 *
 */

// TODO have the server healthcheck return a unique token that the app stores on first request
// if the token doesn't match in the future, do not send credentials to it
// this will help with http routes to make them more secure
const configValues = {
  local2: {
    serverPath: "http://192.168.50.253:8082",
    appRoot: "/home",
  },
  local: {
    serverPath: "https://artem.redirectme.net",
    appRoot: "/home",
  },
  prod: {
    serverPath: "https://artemcams.serveo.net",
    appRoot: "/home",
  },
  dev: {
    serverPath: "http://localhost:8082",
    appRoot: "/home",
  },
};

type ConfigValues = typeof configValues;
export type ConfigEnv = keyof ConfigValues | "offline";
export type ConfigTypes = keyof ConfigValues["local"];

let currentEnv: ConfigEnv =
  (localStorage.getItem("env") as ConfigEnv) || "prod";

export function setConfigEnv(env: ConfigEnv) {
  currentEnv = env;
  localStorage.setItem("env", env);
}

export function getConfigEnv() {
  return currentEnv;
}

export function isHighBandwidth() {
  if (currentEnv === "local2" || currentEnv === "dev") return true;
}

const config = <T extends ConfigTypes>(
  key: T,
  env: ConfigEnv = currentEnv
): ConfigValues["local"][T] => {
  if (env === "offline") return configValues.prod[key];
  return configValues[env][key];
};

export default config;
