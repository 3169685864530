import authHelper from '../../../utils/authHelper';

export interface CookpadRecipe {
  id: string;
  title: string;
  makeCount: number;
  link: string;
  image: string;
  ingredients: { name: string, amount: string }[]
};

export const searchProvider = {
  search: async (text: string): Promise<CookpadRecipe[]> => {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const url = `http://192.168.50.117:8082/search?q=${text}`;
    // const url = `${config("serverPath")}/api/cookbook/search?q=${text}`;
    const res = await fetch(url, {
      headers: {
        Authorization: token,
      },
    });
    const results = await res.json() as CookpadRecipe[];
    return results;
  },
}