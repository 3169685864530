export function cacheFn<T extends { (...arg: any[]): Promise<any> }>(method: T, ttl = 1000): T {
  let cachedValue: any;
  let cacheExpiration = 0;
  const cached = async (...args: any[]) => {
    if (Date.now() < cacheExpiration) return cachedValue;
    cacheExpiration = Date.now() + ttl;
    cachedValue = method(...args);
    return cachedValue;
  };
  return cached as T;
}
