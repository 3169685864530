import config from "../config";
import authHelper from "../utils/authHelper";

class PianoProvider {
  async getSongs(): Promise<string[]> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/piano/songs`, {
      headers: {
        Authorization: token
      }
    });
    const songs = await res.json();
    if (res.status >= 400) throw new Error(res.statusText);
    return songs;
  }
  async getLastPlayed(): Promise<string> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(`${config("serverPath")}/api/piano/last_played`, {
      headers: {
        Authorization: token
      }
    });
    const lastSong = await res.json();
    if (res.status >= 400) throw new Error(res.statusText);
    return lastSong;
  }
  async playSong(song: string): Promise<void> {
    const token = authHelper.getToken();
    if (token === null) throw new Error("UNAUTHORIZED");
    const res = await fetch(
      `${config("serverPath")}/api/piano/play?f=${song}`,
      {
        method: "POST",
        headers: {
          Authorization: token
        }
      }
    );
    if (res.status >= 400) throw new Error(res.statusText);
  }
}

const pianoProvider = new PianoProvider();

export default pianoProvider;
export { PianoProvider };
