import storageHelper from "./storageHelper";

interface Auth {
  token: string;
}

class AuthHelper {
  auth: Auth | null = null;
  setAuth(auth: Auth) {
    this.auth = auth || null;
    storageHelper.set("auth", auth);
  }
  clearAuth() {
    this.auth = null;
    storageHelper.clear("auth");
  }
  getToken() {
    if (this.auth === null) this.setAuth(storageHelper.get("auth"));
    if (this.auth === null) return null;
    return this.auth.token;
  }
}

const authHelper = new AuthHelper();
export default authHelper;
export { AuthHelper };
