import React, { useEffect, useState } from "react";
import { Ingredient, IngredientAmount, Recipe } from "../models/cookbookModels"
import { getIngredientNutrition, getRecipeNutrition } from "../utils/recipeHelper"
import Input from "../../../components/input/input";
import Option from "../../../components/option/option";
import SuggestInput from "../../../components/suggestInput/suggestInput";
import { useAsync } from "../../../utils/useAsync";
import { ingredientProvider } from "../providers/ingredientsProvider";
import { getAllUnits } from "../utils/units";
interface Props {
  ingredient: Ingredient
  onSave: { (ingredient: Ingredient): unknown };
}

export const IngredientForm = ({ ingredient, onSave }: Props) => {
  const { error } = getIngredientNutrition({ item: ingredient, quantity: 1 });
  const units = getAllUnits().map(label => ({ label }));

  return (
    <tr key={ingredient.id}>
      <td>
        <Input
          value={ingredient.name}
          onChange={(name) => onSave({ ...ingredient, name })}
          style={{ width: 300 }}
        />
      </td>
      <td>
        <Option
          value={{ label: ingredient.unit }}
          options={units}
          onChange={(unit) => onSave({ ...ingredient, unit: unit.label })}
          style={{ width: 120 }}
        />
      </td>
      <td>
        <Option
          value={{ label: ingredient.type || '' }}
          options={['', 'veggie', 'protein', 'starch', 'seasoning', 'other'].map(label => ({ label }))}
          onChange={(value) => onSave({ ...ingredient, type: value.label as any })}
          style={{ width: 120 }}
        />
      </td>
      <td>
        <Input
          value={ingredient.nutrition || {}}
          onChange={(nutrition) => onSave({ ...ingredient, nutrition })}
          isTextArea
          style={{ width: 300, height: 100 }}
        />
      </td>
      <td>
        <Input
          value={ingredient.conversions || {}}
          onChange={(conversions) => onSave({ ...ingredient, conversions })}
          isTextArea
          style={{ width: 300, height: 100 }}
        />
      </td>
      <td>{error ? error.message : ''}</td>
    </tr>
  )
}


export const IngredientFormShort = ({ ingredient, onChange }: { ingredient: IngredientAmount; onChange: { (r: IngredientAmount): unknown } }) => {
  const units = getAllUnits().map(label => ({ label }));
  const [allIngredients] = useAsync(ingredientProvider.getAll, [], []);
  return (
    <span>
      <SuggestInput
        value={{ label: ingredient.item.name || '' }}
        options={allIngredients.map(i => ({ label: i.name || '', value: i }))}
        onChange={(item) => {
          let unit = ingredient.item.unit;
          if (item.value && item.value.unit) unit = item.value.unit;
          onChange({ ...ingredient, item: { ...ingredient.item, ...(item.value || {}), unit, name: item.label } });
        }}
        style={{ width: 300 }}
      />
      <Option
        value={{ label: ingredient.item.unit }}
        options={units}
        onChange={(unit) => onChange({ ...ingredient, item: { ...ingredient.item, unit: unit.label } })}
        style={{ width: 120 }}
      />
      <Input value={ingredient.quantity} onChange={(quantity) => onChange({ ...ingredient, quantity })} />
    </span>
  );
}