import React from "react";
import Header from "../../components/header/header";

import { Ingredient } from "./models/cookbookModels";

import { useAsync } from "../../utils/useAsync";
import { ingredientProvider } from "./providers/ingredientsProvider";
import { callDebounced } from "../../utils/debounce";
import { IngredientForm } from "./components/IngredientForm";
import { CookbookMenu } from "./components/CookbookMenu";



export const IngredientList: React.FC = () => {

  const [allIngredients, setAllIngredients] = useAsync(ingredientProvider.getAll, [], [] as Ingredient[]);
  const updateIngredient = (ing: Ingredient) => setAllIngredients(oldIng => {
    const newIng = [...oldIng];
    const idx = newIng.findIndex(i => i.id === ing.id);
    if (idx !== -1) newIng.splice(idx, 1, ing);
    return newIng;
  });

  return (
    <React.Fragment>
      <Header />
      <CookbookMenu currentPage="ingredients" />
      <table>
        <tbody>
          {allIngredients.sort((a, b) => a.name > b.name ? 1 : -1).map(ingredient => {

            return (
              <IngredientForm ingredient={ingredient} onSave={newIngredient => {
                callDebounced(() => {
                  updateIngredient(newIngredient);
                  ingredientProvider.update(newIngredient);
                }, { delay: 1000 });
              }} />
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};
