import React, { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import config from "../../config";
import LoginRequired from "../loginRequired/loginRequired";
import IconHamburger from "../iconHamburger/iconHamburger";
import userProvider from "../../providers/userProvider";
import { useUser } from "../../utils/useUser";

const links: { [url: string]: { name: string, capability?: string } } = {
  "": { name: "Home" },
  toto: { name: "Toto", capability: 'toto' },
  cookbook: { name: "Cookbook", capability: 'cookbook' },
  cams: { name: "Cams" },
  // gate: { name: "Gate" },
  // doorman: { name: "Doorman" },
  media_player: { name: "Media Player" },
  // sec_footage: { name: "Security Footage" },
  devices: { name: "Devices" },
  gallery: { name: "Gallery", capability: 'gallery' },
  // charts: { name: "Charts" },
  settings: { name: "Settings", capability: 'admin' },
  // nearby: { name: "Nearby" },
  login: { name: "Login" },
  logout: { name: "Logout" },
};

Object.keys(links).forEach((url) => {
  links[`${config("appRoot")}/${url}`] = links[url];
  delete links[url];
});

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { hasCapability } = useUser();

  const isOpenClass = [styles.drawer, drawerOpen ? styles.isOpen : ""].join(
    " "
  );
  let title = "";

  const visibleLinks = Object.keys(links).filter(url => {
    const { capability } = links[url];
    if (capability && !hasCapability(capability)) return false;
    return true;
  })

  Object.keys(links).forEach((url) => {
    if (window.location.pathname.match(new RegExp(`^${url}`)))
      title = links[url].name;
  });
  return (
    <div className={styles.navbar}>
      <LoginRequired />
      <div className={styles.title}>{title}</div>
      <div className={styles.hamburgerIcon}>
        <IconHamburger value={drawerOpen} onChange={setDrawerOpen} />
      </div>
      <div className={isOpenClass}>
        {visibleLinks.map((url) => (
          <Link key={url} to={url}>
            {links[url].name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Header;
