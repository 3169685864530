import React, { useState } from "react";
import styles from "./button.module.css";

interface PropTypes {
  onClick?: { (): unknown };
  flat?: boolean;
}

const Button: React.FC<PropTypes> = props => {
  const [clicked, setClicked] = useState(false);

  return (
    <div className={styles.wrapper}>
      <a
        onClick={() => {
          setClicked(true);
          props.onClick && props.onClick();
          setTimeout(() => { setClicked(false) }, 500);
        }}
        className={[clicked ? styles.clicked : '', styles.link, props.flat ? styles.flat : ''].join(' ')}
      >
        <span className={styles.content}>
          {props.children}
        </span>
      </a>
    </div >
  );
};

export default Button;
