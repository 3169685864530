import React, { useState } from "react";
import { Recipe } from "../models/cookbookModels"
import { getIngredientNutrition, getRecipeNutrition } from "../utils/recipeHelper"

interface Props {
  recipe: Recipe;
  drag?: { (recipe: Recipe): any };
  onEdit?: { (): unknown };
}

const backgroundColors: { [type: string]: string } = {
  'Meal': 'rgb(173 194 255)',
  'Protein': 'rgb(251 194 194)',
  'Veggie': 'rgb(151 221 170)',
  'Starch': 'rgb(255 253 194)',
}

export const RecipeCard = ({ recipe, drag, onEdit }: Props) => {
  const { protein, calories, veggies, carbs } = getRecipeNutrition(recipe)
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      style={{
        display: 'inline-block',
        border: '1px solid black',
        margin: 10,
        padding: 10,
        width: 250,
        background: backgroundColors[recipe.type || ''] || '#FFFFFF'
      }
      }
      draggable
      onDragStart={drag && drag(recipe)}
    >

      {recipe.imageUrl ? <img src={recipe.imageUrl} style={{ width: 50, height: 50, objectFit: 'cover', float: 'left', paddingRight: 5 }} /> : null}
      <div style={{
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }} title={recipe.name}>{recipe.name}</div>
      <div>
        <span style={{ fontSize: 11 }}>
          {Math.round(calories || 0)}cal |
          {Math.round(protein || 0)}p |
          {Math.round(veggies || 0)}v |
          {Math.round(carbs || 0)}c
        </span>
        <span style={{ float: 'right', paddingLeft: 10 }}>
          {recipe.webUrl ? <a target="_blank" href={recipe.webUrl}><button>link</button></a> : null}&nbsp;
          {onEdit ? <button onClick={onEdit}>edit</button> : null}&nbsp;
          <button onClick={() => setIsOpen(o => !o)}>info</button>

        </span>
      </div>

      {isOpen ? (
        <table>
          <tr>
            <td>Name</td>
            <td>QTY</td>
            <td>Calories</td>
            <td>Protein (g)</td>
          </tr>
          {
            (recipe.ingredients || []).map(item => {
              const { calories, protein } = getIngredientNutrition(item);
              const totalCalories = calories;
              const totalProtein = protein;
              return (
                <tr>
                  <td>{item.item.name}</td>
                  <td>{item.quantity}{item.item.unit}</td>
                  <td>{Math.round(totalCalories)}</td>
                  <td>{Math.round(totalProtein)}</td>
                </tr>
              )
            })
          }
        </table>
      ) : null}
    </div>
  )
}