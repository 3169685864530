import React, { useEffect, useState } from "react";
import Toggle from "../../components/toggle/toggle";
import { callDebounced } from "../../utils/debounce";
import styles from "./colorLight.module.css";

export interface LightStatus {
  on: boolean;
  hue: number; // hue = 0-1 // 0 - 360
  sat: number; // sat = 0-1 // 0 - 100
  temp: number; // temp = 0-1 // 153-500
  brightness: number; // brightness = 0-1 // 0 - 255
}

interface ColorLightProps {
  status?: Partial<LightStatus>;
  onClose: { (): unknown };
  onChange: { (status: Partial<LightStatus>): unknown };
  name: string;
  color?: boolean;
  dimmer?: boolean;
}
export const ColorLight = (props: ColorLightProps) => {
  const status = props.status || {};
  const [on, setOn] = useState(status.on !== undefined ? status.on : false);
  const [hue, setHue] = useState(status.hue !== undefined ? status.hue : 0);
  const [brightness, setBrightness] = useState(
    status.brightness !== undefined ? status.brightness : 100
  );
  const [saturation, setSaturation] = useState(
    status.sat !== undefined ? status.sat : 1
  );

  console.log(status.brightness, brightness);

  useEffect(() => {
    if (status.on !== undefined) setOn(status.on);
  }, [status.on]);
  useEffect(() => {
    if (status.brightness !== undefined) setBrightness(status.brightness);
  }, [status.brightness]);
  useEffect(() => {
    if (status.hue !== undefined) setHue(status.hue);
  }, [status.hue]);
  useEffect(() => {
    if (status.sat !== undefined) setSaturation(status.sat);
  }, [status.sat]);

  const getStatus = () => ({
    on,
    hue,
    sat: saturation,
    temp: 0.5,
    brightness,
  });

  return (
    <>
      <div
      // className={styles.overlay + " " + styles.fade}
      // onClick={props.onClose}
      ></div>

      <div
        className={styles.bottomLockContainer}
        // className={styles.overlay}
        onClick={props.onClose}
      >
        <div
          className={styles.bottomLock}
          // className={styles.modal}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.title}>
            <span>{props.name}</span>
            <Toggle
              value={on}
              onChange={(on) => {
                callDebounced(() => props.onChange({ on }));
                setOn(on);
              }}
            />
          </div>
          {(props.dimmer || props.color) && on ? (
            <>
              <div className={styles.section}>Brightness:</div>
              <div
                style={{
                  height: 10,
                  width: 360,
                  margin: 2,
                  background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,255) 100%)`,
                }}
              ></div>
              <input
                type="range"
                min={0}
                max={255}
                value={brightness * 255}
                onChange={(e) => {
                  const brightness = parseInt(e.target.value) / 255;
                  callDebounced(() => props.onChange({ brightness }));
                  setBrightness(brightness);
                }}
                style={{ width: 360 }}
              />
            </>
          ) : null}
          {props.color && on ? (
            <>
              <div className={styles.section}>Hue:</div>
              <div>
                {(() => {
                  let arr: number[] = new Array(360);
                  for (let i = 0; i < 360; i++) arr[i] = i;
                  return arr.map((color) => (
                    <span
                      key={color}
                      style={{
                        background: `hsl(${color}, 100%, 50%)`,
                        display: "inline-block",
                        width: 1,
                        height: 10,
                      }}
                    >
                      {" "}
                    </span>
                  ));
                })()}
              </div>
              <input
                type="range"
                min={0}
                max={360}
                value={hue * 360}
                onChange={(e) => {
                  const hue = parseInt(e.target.value) / 360;
                  callDebounced(() => props.onChange({ hue, sat: saturation }));
                  setHue(hue);
                }}
                style={{ width: 360 }}
              />
              <div className={styles.section}>Saturation:</div>
              <div
                style={{
                  height: 10,
                  width: 360,
                  margin: 2,
                  background: `linear-gradient(90deg, hsl(${hue},0%,100%) 0%, hsl(${hue},100%,50%) 100%)`,
                }}
              ></div>
              <input
                type="range"
                min={0}
                max={100}
                value={saturation * 100}
                onChange={(e) => {
                  const sat = parseInt(e.target.value) / 100;
                  callDebounced(() => props.onChange({ hue: hue / 360, sat }));
                  setSaturation(sat);
                }}
                style={{ width: 360 }}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
