import { objectKeys } from "../../../utils/objectKeys";
import { MealSchedule, Ingredient, IngredientAmount, Nutrition, Recipe, RecipeAmount, Units } from "../models/cookbookModels";
import { convert, ehanceCustomConversions } from "./units";

let allIngredients: Ingredient[] = [];

export const setAllIngredientsForRecipes = (ingredients: Ingredient[]) => allIngredients = ingredients;

export const mealScheduleToIngredientsList = (cookingSchedule: MealSchedule): IngredientAmount[] => {
  const finalIngredients: IngredientAmount[] = [];

  const recipeMap = {} as { [name: string]: RecipeAmount };
  Object.keys(cookingSchedule).forEach(day => {
    cookingSchedule[day].forEach(recipeAmount => {
      const key = recipeAmount.recipe.name;
      if (!recipeMap[key]) recipeMap[key] = { ...recipeAmount };
      else recipeMap[key].quantity += recipeAmount.quantity;
    });
  });

  const recipeList: RecipeAmount[] = [];
  Object.keys(recipeMap).forEach(r => {
    recipeList.push(recipeMap[r]);
  })

  recipeList.forEach(dish => {
    (dish.recipe.ingredients || []).forEach(ingredient => {
      let listItem = finalIngredients.find(i => i.item.name === ingredient.item.name);
      if (!listItem) {
        let foundItem = allIngredients.find(i => i.name === ingredient.item.name) as Ingredient;
        if (!foundItem) {
          console.log('Ingredient not found', ingredient.item.name);
          foundItem = ingredient.item;
        }
        listItem = { item: foundItem, quantity: 0 };
        finalIngredients.push(listItem);
      }

      let conversion = 0;

      try {
        conversion = convert(ingredient.item.unit, listItem.item.unit, listItem.item.allConversions, listItem.item.name);
      } catch (e) { }

      listItem.quantity += ingredient.quantity * dish.quantity * conversion;
    });
  });

  finalIngredients.sort((a, b) => (a.item.name || '').toLowerCase() > (b.item.name || '').toLowerCase() ? 1 : -1)

  return finalIngredients;
}


export const getIngredientNutrition = (ingredient: IngredientAmount) => {
  let error: Error | null = null;
  let foundIngredient = allIngredients.find(all => all.name === ingredient.item.name)
  if (!foundIngredient) foundIngredient = ingredient.item;
  let conversion = 0;
  try {
    conversion = convert(ingredient.item.unit, 'g', foundIngredient.allConversions, ingredient.item.name);
  } catch (e) {
    error = e as Error;
  }
  const grams = ingredient.quantity * conversion;

  const _calories = (foundIngredient.nutrition && foundIngredient.nutrition.calories) || 0;
  const calories = _calories * grams / 100;

  const _protein = (foundIngredient.nutrition && foundIngredient.nutrition.protein) || 0;
  const protein = _protein * grams / 100;

  const veggies = foundIngredient.type === 'veggie' ? grams : 0;
  const carbs = foundIngredient.type === 'starch' ? grams : 0;

  return { protein, calories, veggies, carbs, error };
}

const sumNutrition = (a: Nutrition, b: Nutrition) => {
  return {
    protein: (a.protein || 0) + (b.protein || 0),
    calories: (a.calories || 0) + (b.calories || 0),
    veggies: (a.veggies || 0) + (b.veggies || 0),
    carbs: (a.carbs || 0) + (b.carbs || 0),
  }
}

export const getRecipeNutrition = (recipe: Recipe) => {
  let nutrition = {} as Nutrition;
  (recipe.ingredients || []).forEach(ingredient => {
    const addedNutrition = getIngredientNutrition(ingredient);
    nutrition = sumNutrition(nutrition, addedNutrition);
  });
  return nutrition;
}

const getRecipeAmountNutrition = (recipe: RecipeAmount) => {
  const nutrition = getRecipeNutrition(recipe.recipe);
  objectKeys(nutrition).forEach(k => {
    nutrition[k] = (nutrition[k] || 0) * recipe.quantity;
  });
  return nutrition;
}

export const getMealNutrition = (recipes: RecipeAmount[]) => {
  let nutrition = {} as Nutrition;
  recipes.forEach(recipe => {
    const addedNutrition = getRecipeAmountNutrition(recipe);
    nutrition = sumNutrition(nutrition, addedNutrition);
  });
  return nutrition;
}

